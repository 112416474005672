
.table-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  //pading: 50px 10px;

  .processing-payment{
    color: #666666;
  }

  .table-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //background: #e40621;
    margin: 10px 30px;

    .main-info-container {
      //background: #5856d6;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 5px;
    }

    .left-inner-row-container {
      //background: #f1c40f;
      display: flex;
      flex-direction: column;

      .row-date {
        color: #666666;
      }

      .display-name {
        font-weight: bold;
        font-size: 1.5em;
      }
    }

    .user-purchase-stats {
      color: #4C4C4C;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }


    .right-inner-row-container {
      display: flex;
      flex-direction: row;
      //background: #155724;
      justify-content: space-between;

      .count-container {
        //background-color: #155724;
        margin-top: 2px;
        font-size: 2em;
        font-weight: bold;
      }
    }
  }
}
