@import "src/01_application/styles/colours";


.labelText {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.subLabelText {
  margin-top: -4px;
  margin-bottom: 10px;
  color: #4C4C4C;
  font-size: smaller;
}

.textInput {
  padding: 12px 10px;
  //font-size: large;
  font-size: 16px;
  //background: #bc6767;
  width: 90%;
  //height: 56px;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  position: relative;
  transition: 0.3s all;
  margin-bottom: 25px;

  &:focus {
    border: 2px solid #000000;
    outline: none;

  }

}

.error {
  border: 5px solid #e40621;
}


.buttonSection {
  //background: #e40621;
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;


  @media (max-width: 400px) {
    flex-direction: column-reverse;
  }

}

.nextStepBtn {
  display: inline-flex;
  background: $cta;
  font-weight: bold;
  color: white;
  height: 45px;
  width: 200px;
  border-radius: 35px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  //font-size: 2em;
}

.backBtnSection {
  //background: #155724;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;

  .backBtn {
    display: flex;
    //margin: 5px;
    border: 2px solid #e0e0e0;
    height: 50px;
    width: 90px;
    border-radius: 35px;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size: 1em;
    color: #9b9b9b;;

    @media (max-width: 400px) {
      height: 40px;
      width: 195px;
      margin-top: 10px;
    }


    &:hover {
      border: 4px solid black;
      height: 53px;
      width: 86px;
      color: black;
      transition: 0.35s
    }
  }
}
