
.container {
  //margin-top: 30%;
  margin: 30vh 10px 10px 10px;
  //background: #f1c40f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-height: 700px) {
    margin: 15vh 10px 10px 10px;
  }

}

.headerText{
  font-size: 3em;
  font-weight: bold;
  text-align: center;
}

.subHeadingText{
  color: #5e5e5e;
  text-align: center;
  margin-top: 10px;
}

.iconBlock{
  //background: #f1c40f;
  margin-bottom: 30px;
  height: 90px;
  width: 90px;
}
.icon {
  //background: #1f455f;
  width: 90px;
  height: 90px;
}