@import "src/01_application/styles/colours";

.HeaderNavItem {
  position: relative;
  font-size: 1.8rem;
    //background: #3b5998;

  @media (max-width:992px) {
    font-size: 2.4rem;
      //margin-left: 30px;
    //padding-left: 30px;
    //background: purple;

    &.list-inline-item {
      display: block;
      //margin-left: 30px;
      //margin-right: 30px;
      width: 80%;
      margin-left: 30px;
    }


  }

  &__link {
    text-decoration: none;
    position: relative;
    color: #363737;
    //font-family: "Orator Std", Sans-Serif;

    @media (max-width:992px) {
      padding: 8px 0;
    }


    &--active,
    &--active:focus,
    .HeaderNavItem--active & {
      color: $cta;
    }


  }


}
