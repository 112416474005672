
.headerBlock {
  margin: 100px 0px 30px 0px;

  .headerBlock-text {
    font-size: 5rem;
    font-weight: bold;
    text-align: center;
  }
}


.block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px 0px;
  //background: #4f7477;

  .titleBlock{
    .titleBlock-text{
      font-size: 4rem;
      font-weight: bold;
      text-align: start;
    }
  }


  .contentBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    //margin-top: 40px;
    max-width: 900px;

    @media (min-width: 1200px) {
      flex-direction: row;
    }

    @media (max-width: 500px) {
      flex-direction: column;
    }

    .contentBlock_image {
      margin: 10px 5px 10px 5px;
      width: 50vw;
      height: 50vw;
      max-width: 350px;
      max-height: 350px;
      border-radius: 50%;

      @media (max-width: 500px) {
        width: 80vw;
        height: 80vw;
      }

    }


    .image {
      width: 50vw;
      height: 50vw;
      max-width: 350px;
      max-height: 350px;

      //@media (min-width: 1200px) {
      //  width: 40vw;
      //  height: 40vw;
      //}
      //
      @media (max-width: 500px) {
        width: 80vw;
        height: 80vw;
      }
    }


    .contentBlock_descriptionBlock {
      padding: 10px 20px 10px 20px;

      //background: #1f455f;
      max-width: 400px;

      .contentBlock_descriptionBlock_text {
        line-height: 1.5;
        margin-bottom: 20px;
      }
    }
  }
}

.footer {
  height: 70px
}





