@import "../../../01_application/styles/const";
@import "../../../01_application/styles/colours";


.modalContainer {
  background: $darkTransparentScreen;
  //background: #0a66b7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh + 60px);
  z-index: 50;
  min-height: calc(100vh + 60px);
  margin-top: -$headerHeight;
  overflow-y: scroll;
  //overflow-y: hidden;

  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}



.modalCard {
  background: $grey000;
  padding: 10px 10px;
  min-height: 200px;
  margin: -10vh 5px 0;
  width: 80%;
  max-width: 500px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;

  @media (max-height: 700px) {
    //margin-top: 5vh;
  }

  @media (max-width: 450px) {
    margin: -20vh 5px 0;
    width: 90%;
    //height: 700px;
  }

}

.modalContent{
  margin: 0px 10px;
  //width: 95%;
  //background: #9b9b9b;
}


.headerButtonBlock {
  margin-top: -15px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  //background: #185c53;

  .icon {
    margin-top: 20px;
    margin-right: 10px;
    width: 25px;
    height: 25px;

    &:hover {
      cursor: pointer;
    }
  }
}


.buttonBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (min-width: 500px) {
    justify-content: flex-end;
  }

  @media (max-width: 400px) {
    flex-direction: column;
  }
}
