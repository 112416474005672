@import "src/01_application/styles/colours";

.pageContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
//
//.statsContainer{
//    display: flex;
//    flex-direction: column;
//    margin-top: 40px;
//    margin-left: auto;
//    margin-right: auto;
//    width: 90vw;
//    max-width: 1000px;
//    //border: solid #000;
//    //border-radius: 20px;
//    //border-width: 1px;
//    //background: #155724;
//
//    .stallDetails{
//        //background: #bc6767;
//        display: flex;
//        flex-direction: column;
//        margin: 30px 30px 0 30px;
//
//        .stallName{
//            font-weight: bold;
//        }
//
//        .stallAddress{
//            color: #4C4C4C;
//        }
//    }
//
//    .graph{
//        //background: #1f455f;
//        align-self: center;
//        margin-left:-40px;
//        margin-top: 5px;
//        padding: 10px 20px;
//    }
//}

.backBtnSection{
  //background: #155724;
  //margin-bottom: 20px;
  margin-top: 30px;
  display: flex;
  width: 80vw;
  max-width: 800px;


  .backBtn{
    display: flex;
    margin: 5px;
    border: 2px solid #e0e0e0;
    height: 40px;
    width: 90px;
    border-radius: 35px;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size: 1em;
    color:#9b9b9b;;


    &:hover {
      border: 4px solid black;
      height: 36px;
      width: 86px;
      color: black;
      transition: 0.35s
    }
  }
}
//
//.emptyStateBlock {
//  min-height: 40vh;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  flex-direction: column;
//
//  .plateIcon {
//    width: 80px;
//    height: 80px;
//    margin-bottom: 30px;
//  }
//}
//
//.boldStallName {
//  font-weight: bold;
//}

.purchaseContainer{
    //background: $cardColour;
    //background: #155724;
    margin: 0px auto 40px auto;
    width: 70%;
    max-width: 800px;
    border-radius: 30px;
    padding:10px;

    @media (max-width:660px) {
        width: 90vw;
        padding:20px 10px;
    }
}

.optionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 5px;
    //background: #155724;
    width: 70vw;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width:660px) {
        width: 90vw;
    }

    .unselected {
        color: black;
        margin: 0px 10px
    }

    .selected {
        font-weight: bold;
        color: black;
        margin: 0px 10px
        //background: #155724;
    }

}

.selectionBlock {
  display: flex;
  flex-direction: row;
  //background: #f1c40f;
  //justify-content: center;
  align-items: center;
  width: 70vw;
  max-width: 800px;

  @media (max-width:660px) {
    width: 90vw;
  }
}

.selectionField {
  margin-top: 10px;
  padding: 12px 10px;
  //font-size: large;
  //background: #bc6767;
  width: 50%;
  min-width: 300px;
  //height: 56px;
  border: 2px solid #e0e0e0;
  border-radius: 15px;
  position: relative;
  transition: 0.3s all;
  font-family: 'Arial', Sans-Serif;


  &:focus {
    border: 3px solid #000000;
    outline: none;

  }

  &__error {
    border: 3px solid #e40621;
  }
}