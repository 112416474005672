@import "src/01_application/styles/const.scss";
@import "src/01_application/styles/colours.scss";


.menuContainer{
  //background: #f1c40f;
  //padding-top: 40px;
  //margin-top: 10px;
}


.menuBlock {
  //margin-top: 50px;
  position: fixed;
  width: 270px;
  //height: 400px;
  background: white;
  box-shadow: 1px 1px 10px 5px rgba(49, 49, 49, 0.15);
  border: 0.2px solid #f0f0f0;
  border-radius: 5px;
  right: 10px;
  top: calc(#{$headerHeight} + 10px);
}

.itemsContainer {
  display: flex;
  flex-direction: column;
  //background: #ab2939;
  text-align: center;
  margin: 30px 0px;
}


.userIconBlock{
  //width: 100%;
  //background: #ab2939;
  display: flex;
  justify-content: center;
}
.userIcon {
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background: $grey800;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 2px 0px 10px 0px;
}

.userIconText{
  color: $grey000;
}

.name{
//margin-top: 30px;
  font-weight: bold;
  font-size: larger;
}

.email {
  margin-top: 5px;
  margin-bottom: 20px;
  color: #5f5f5f;
}

.shopName{
  margin-top: 10px;
  color: #5f5f5f;
}

.table{

}



.tableItem{
  display: flex;
  align-items: center;
  margin: 5px 30px;
  color: #6fa69e;
}

.bagIcon{
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.lineSeparator {
  height:1px;
  background: #dddddd;
  margin: 10px 15px;
}

.signOutText{
  color: $cta;
  font-style: italic;
  margin: 50px;
}