@import 'src/01_application/styles/colours';

.container{
  //background: #efefef;
  margin: 180px 0px 50px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.subContainer {
  width: 100%;
  //max-width: 800px;
  flex-direction: column;
  //display: flex;
  //justify-content: center;
  //align-items: center;
//  margin-bottom: 100px;
//  //background: #FCE205;
//
}


.rowContainer {
//background: #1f455f;
  width: 95%;
  margin-top: 20px;

}

.rowBlock{
  display: flex;
  flex-direction: row;
}

.leftBlock{
//background: #FCE205;
  flex: 0.95;
}

.rightBlock {
//background: #8ab2b3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.05;

}

.emptyMealsContainer {
  display: flex;
  flex-direction: column;
  //background: #1f455f;
  width: 95%;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.iconBlock{
  margin: 20px 0px 20px 0px;
  height: 60px;
  width: 60px;
  //background: #00c3cf;
}
.icon {
  //background: #1f455f;
  width: 60px;
  height: 60px;
}

.lineSeparator {
  height: 1px;
  //width: 90%;
  background: #cccccc;
  margin: 10px 20px;
}

.height {
  height:20px
}

.noDonationText{
  color: #656565;
}

.subText{
  color: #656565;
}
.subInfoBlock {
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  color: #656565;
}

.keyBlock{
  min-width: 120px;
  flex: 0.2;
}

.dataBlock{
  flex: 0.8;
}

.infiniteScroll{
  //width: 90%;
  //background: #74B8AE;
}

.periodContainer{
//background: #74B8AE;
  display: flex;
  justify-content: center;
}

.periodBlock {
  background: #FFFFFF;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  //background: #37b9a2;
  box-shadow: 5px 15px 40px  rgba(49, 49, 49, 0.15);
  border-radius: 5px;
  //min-height: 200px;
  width: 90%;
  max-width: 800px;
  margin: 30px 5px 5px 5px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }
}

.headerBlock {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerText{
  //background: #74B8AE;
  font-weight: bold;
  font-size: 3em;
  //margin-left: 10vw;
  width: 90%;
  max-width: 800px;
}

.periodHeaderText {
  margin: 10px 20px;
}

.nameBlock {
  display: flex;
  flex-direction: row;
}

.nameText{
  font-weight: bold;
  color: #2a2a2a;
  font-size: 1.3em;
}

.paymentBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  //background: #1f455f;
}

.periodHeaderBlock {
  background: $cta;
  color: #FFFFFF;
  font-weight: bold;
  //border-radius: 4px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
}

.loading {
  width: 100%;
  display: grid;
  place-items: center;
  margin: 100px 0px;
}

.chevronIcon{
  width: 35px;
  height: 55px;
}

.visaIcon {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  //margin-bottom: 50px;

  @media (max-width: 390px) {
    width: 25px;
    height: 25px;
    //margin-bottom: 25px;
  }
}

.masterCardIcon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  //margin-bottom: 45px;

  @media (max-width: 390px) {
    width: 30px;
    height: 30px;
    //margin-bottom: 35px;
  }
}

.amexIcon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  //margin-left: -10px;
  //margin-bottom: 30px;

  @media (max-width: 390px) {
    width: 30px;
    height: 30px;
    //margin-left: -10px;
    //margin-bottom: 20px;
  }
}