@import "src/01_application/styles/const";
@import "src/01_application/styles/colours";


.addressCard {
  background: #FFFFFF;
  display: flex;
  align-items: center;
  //background: #37b9a2;
  box-shadow: 5px 15px 40px  rgba(49, 49, 49, 0.15);
  border-radius: 5px;
  width: 300px;
  min-height: 200px;
  margin: 10px 5px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }

  @media (max-width: 360px) {
    min-height: 170px;
  }
}

.selected {
  border: solid 3px $cta;
}

.addressDetailsBlock {
  //background: #185c53;
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  padding: 10px 0px;
  color: #757575;
  width: 100%;
}

.detailsBlock {
  margin-top: -20px;
}

.stampBlock {
  display: flex;
  width: 100%;
  //background: #f1c40f;
  justify-content: space-between;
  align-items: flex-start;
}

.spacerBelowContactDetails {
  height: 10px
}

.stampIcon {
  margin-right: -5px;
  height: 50px;
  width: 50px;
}
