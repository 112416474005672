.loading {
  width: 100%;
  display: grid;
  place-items: center;
  //margin: 100px 0px;
  //margin: 30vh 10px 10px 10px;
  height: 100px;
  //background: #37b9a2;

  @media (max-height: 700px) {
    margin: 15vh 10px 10px 10px;
  }

}