@import "src/01_application/styles/colours";

.pageContainer {
  margin-top: 100px;
  //background: #4f7477;
}

.aboutUsBlock {
  margin: 10px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  //background: #00c3cf;

  .aboutUsText {
    font-size: 5rem;
    font-weight: bold;
  }
}

.coverImageBlock {
  //background: #3b5998;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  //background: #1f455f;
  padding: 10px 0px 30px 0px;
  //height: 30vw;

  .coverImage{
    width: 60vw;

  }
}

.link-block {
  //background: #1f455f;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;


  .link-icon {
    width: 70px;
    height: 70px;
    //color: #666666 ;
    //filter: invert(50%) sepia(98%) saturate(12%) hue-rotate(314deg) brightness(100%) contrast(91%);
  }
}


.descriptionBlock{
  padding: 0px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //background: #1f455f;


  @media (min-width:1200px) {

  }

  @media (max-width:500px) {

  }


  .descriptionText{
    max-width: 600px;
    //background: #02BAA3;
    margin-bottom: 15px;
    //font-weight: lighter;
    line-height: 1.5;
    text-align: center;
  }
}

.concernBlock{

  .concernTitleBlock{
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .concernTitleText {
      max-width: 600px;
      margin: 20px 20px 0px 20px;
      font-weight: bold;
      //background: #4f7477;
    }
  }



  .concernContentBlock{
    padding: 10px 40px 10px 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width:1200px) {
      flex-direction: row;
    }

    @media (max-width:500px) {
      flex-direction: column;
    }

    .phoneBlock {
      display: flex;
      align-items: center;
      justify-content: center;

      .phoneImage{
        width: 70vw;
        max-width: 200px;
      }
    }


    .concernDescriptionBlock {
      //background: #3b5998;
      align-items: center;
      justify-content: center;
      max-width: 350px;
      padding: 10px 10px 10px 10px;

      .concernDescriptionText {
        text-align: center;
        line-height: 1.5;
        //font-weight: lighter;
      }
    }
  }


  .transparentPricingBlock {
    margin: 10px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    //background: #00c3cf;

    .transparentPricingText {
      font-size: 5rem;
      font-weight: bold;
      padding:30px;
      margin: 50px 0px 0px 0px;
    }
  }

  .pricingImageBlock {
    //background: #3b5998;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    //background: #1f455f;
    padding: 10px 0px 30px 0px;

    //height: 30vw;
  }

  .pricingImage {
    padding-top: 50px;
    width: 60vw;
    max-width: 700px;
    @media (max-width:700px) {
      width: 90vw;
      padding: 0px 20px 0px 20px;
    }
  }

  .footer{
    height:50px
  }
}
