@import "src/01_application/styles/colours";
@import "src/01_application/styles/colours";


.purchasePreviewPage {
  //background: #1f455f;
  width: 100vw;
  //height: 300vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

}

.buyMealsContainer {
  //background: #8ab2b3;
  //border: 4px solid #000;
  //margin: 0px auto 50px auto;
  width: 90vw;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  padding: 0px 40px;
  margin: 50px 0px 100px 0px;

  @media (max-width: 500px) {
    //width: 80vw;
    padding: 20px;
  }
}



.backBtnSection{
  //background: #155724;
  margin-bottom: 50px;
  margin-top: 10px;

  .backBtn{
    display: flex;
    margin: 5px;
    border: 2px solid #e0e0e0;
    height: 40px;
    width: 90px;
    border-radius: 35px;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size: 1em;
    color:#9b9b9b;;


    &:hover {
      border: 4px solid black;
      height: 36px;
      width: 86px;
      color: black;
      transition: 0.35s
    }
  }
}


.modalContainer {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  //align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh + 60px);
  z-index: 50;
  //margin-top: -$headerHeight;
  overflow-y: scroll;

  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

.modalCard {
  background: #FFFFFF;
  width: 90%;
  height: 300px;
  max-width: 600px;
  margin-top: 20%;
  border-radius: 10px;

  @media (max-height: 700px) {
    margin-top: 10%;
  }

}

.modalHeadingText {
  font-size: 2em;
  font-weight: bold;
  margin: 70px 20px 20px 20px;
}

.buttonBlock {
  display: flex;
  margin: 40px 20px 20px 20px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 400px) {
    flex-direction: column;
  }
}

.secondaryButton {
  background: #ececec;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  //font-weight: bold;
  height: 50px;
  width: 40%;
  border-radius: 10px;
  //margin-top: 30px;


  &:hover {
    font-weight: bold;
    transition: all .3s ease-out;
    color: #000000;
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }
}

.primaryButton {
  background: $cta;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 50px;
  width: 40%;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    color: #ffffff;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }
}

.toastContainer {
  display: flex;
  flex-direction: row;
  //align-items: center;
  //justify-content: space-between;
  //width: auto;
  //background: #ab2939;
  //background: #f1c40f;
  width: 100%;
}
