@import "src/01_application/styles/colours";

.claims-from-purchase-page {
  background: #F4F4F4;
  padding-top: 100px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;


  .receipt {
    //background: #1f455f;
    //height: 500px;
    display: flex;
    width: 70%;
    max-width: 700px;
    flex-direction: column;
    background: #F9F9F9;
    //background: #3b5998;
    margin: 10px 40px 0px 40px;
    border-radius: 40px;
    box-shadow: 10px 10px 50px #e0e0e0;
    align-self: center;

    @media (max-width: 600px) {
      margin: 0px 15px 0px 15px;
      align-self: center;
      //padding: 20px;
      width: 90%;
    }

    .receipt-header {
      background: $receiptHeaderColour;
      height: 200px;
      width: 100%;
      padding: 0px;
      max-width: 700px;
      align-self: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 700px;
      align-self: center;
      border-top-right-radius: 40px;;
      border-top-left-radius: 40px;;

      //@media (max-width:550px) {
      //    margin: 0px 15px 0px 15px;
      //    align-self: center;
      //    padding: 20px;
      //    width: 90%;
      //}


      .receipt-header-logo {
        width: 100px;
      }

      .header-text {
        margin-top: 10px;
        font-weight: bold;
        color: white;
      }
    }

    .details-section {
      padding: 50px;

      @media (max-width: 550px) {
        margin: 0px 15px 0px 15px;
        align-self: center;
        padding: 20px;
        width: 90%;
      }


      .display_name {
        margin-top: 10px;
        font-size: 4rem;
        font-weight: bold;
      }

      .sub_text {
        font-size: 1.3rem;
        padding: 10px 0px 10px 0px;
        color: #4c4c4c;
      }


      .details-container {
        margin: 20px 20px 0px 20px;
        //width: 70%;

        @media (max-width: 550px) {
          margin: 0px 5px 0px 5px;
        }

        .details-sub-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          //background: #1f455f;
          //margin: 30px

          @media (max-width: 550px) {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
          }

          .purchase-details {
            font-weight: bold;
          }
        }
      }


      .separator {
        height: 3px;
        background: #9b9b9b;
        width: 70%;
        margin: 30px 0px 20px 5px;
        align-self: center;
      }


      .claims-summary-container {
        margin-bottom: 15px;
        margin-top: 40px;

        .claims-sub-container {
          font-weight: bold;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 3rem;

          @media (max-width: 550px) {
            font-size: 2rem;
          }
        }
      }

      .empty-state-block {
        min-height: 40vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .plate-icon {
          width: 80px;
          height: 80px;
          margin-bottom: 30px;
        }

        .empty-state-text {
          max-width: 400px;
          text-align: center;
        }
      }


    }

  }


}


.manageRecurringPurchaseContainer {
  //background: #f1c40f;
  display: flex;
  justify-content: flex-end;
}

.manageRecurringPurchaseBtn {

}
