@import "src/01_application/styles/colours";
@import "src/03_views/components_core/core_styles.module.scss";

.vertical {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fieldLabel {
  margin-top: 10px;
  color: $textLabelColour;
}

.formSubLabel {
  margin-top: -20px;

  @media (max-width: 400px) {
    margin-top: -15px;
  }
}

.formField {
  margin-top: -5px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: $fieldPadding;
  color: $textInputColour;
  border-radius: $fieldBorderRadius;
  border-width: $fieldBorderWidth;
  border-color: $fieldBorderColour;
  border-style: solid;
  transition: 0.3s all;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    border-width: $fieldBorderWidth_focus;
    border-color: $fieldBorderColour_focus;
    outline: none;

  }
}

.error {
  border: 2px solid #e40621;
}

.errorBlock {
  margin-top: -5px;
  padding: 5px 10px;
  background: #f1c40f;
  border-radius: 5px;
  font-size: smaller;
  color: $grey800;
}

.footnote{
  margin-top: -10px;
}