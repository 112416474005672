//app rules
//only the top level rules here
@charset "utf-8";

//Top level container

.app-body-block{
    //background: #1f455f;
    position: relative;
    transition: top 0.3s ease-out, margin-bottom 0.3s ease-out, padding-bottom 0.3s ease-out;
    position: relative;


}


.app {
    display: block;
   // background: url(../img/ui/bg.svg) no-repeat center top / cover;
   // background: #74B8AE;

    //&__main {
    //    position: relative;
    //    top: 200px;
    //    //width: calc(100% - 60px);
    //    max-width: 1200px;
    //    //min-height: calc(100vh - 150px);
    //    //padding-bottom: 80px;
    //    //margin: 0 auto 200px;
    //    transition: top 0.3s ease-out, margin-bottom 0.3s ease-out, padding-bottom 0.3s ease-out;
    //
    //    &--fullWidth {
    //        max-width: none;
    //        width: 100%;
    //        top: 90px;
    //    }
    //
    //    &-inner {
    //        width: 100vw;
    //        position: relative;
    //
    //        //@media (max-width:767px) {
    //        //    margin: 0 -5px
    //        //}
    //
    //    }
    //
    //    @media (max-width:1440px) {
    //        width: calc(100% - 40px);
    //
    //        &--fullWidth {
    //            width: 100%;
    //        }
    //
    //    }
    //
    //    &--condensed:not(&--fullWidth) {
    //        top: 140px;
    //        margin-bottom: 140px;
    //    }
    //
    //    @media (max-width:992px) {
    //        top: 108px;
    //        margin-bottom: 108px;
    //
    //        &--fullWidth {
    //            top: 79px;
    //            margin-bottom: 79px;
    //        }
    //
    //    }
    //
    //    @media print {
    //        top: 0;
    //        height: auto;
    //        min-height: 0;
    //        margin: 0;
    //    }
    //
    //}

    //&--no-header &__main {
    //    top: 0;
    //    padding-bottom: 0;
    //    margin-bottom: 0;
    //
    //    @media (max-width:992px) {
    //        padding-top: 40px;
    //        padding-bottom: 0;
    //        margin-bottom: 0;
    //    }
    //
    //}
    //
    //&--no-header &__main--fullWidth {
    //    top: 0;
    //    padding-top: 0;
    //    padding-bottom: 0;
    //    margin-bottom: 0;
    //}

}

//controls preloader vertical position
.transition__holder {
    min-height: 100%;
}

//Route transitions
.transition__crossfade {
    &-enter,
    &-exit {
        transition: opacity 0.3s ease-out;
    }

    &-enter {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
    }

    &-enter-active {
        z-index: 2;
        opacity: 1;
    }

    &-exit {
        opacity: 1;
    }

    &-exit-active {
        z-index: 1;
        opacity: 0;
    }

}

.cover {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
}
