@import "src/01_application/styles/colours";

.pageContainer {
  margin-top: 100px;
  //background: #4f7477;
}

.aboutUsBlock {
  margin: 10px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  //background: #00c3cf;

  .aboutUsText {
    font-size: 5rem;
    font-weight: bold;
    text-align: center;
  }
}

.contentBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-top: 40px;
  //max-width: 900px;
  //background: #1f455f;

  @media (min-width: 1200px) {
    flex-direction: row;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }

  .contentBlock_image {
    margin: 10px 5px 10px 5px;
    width: 70vw;
    height: 70vw;
    max-width: 450px;
    max-height: 450px;
    border-radius: 50%;

    @media (max-width: 500px) {
      width: 80vw;
      height: 80vw;
    }

  }

  .image {
    width: 70vw;
    height: 70vw;
    max-width: 450px;
    max-height: 450px;

    //@media (min-width: 1200px) {
    //  width: 40vw;
    //  height: 40vw;
    //}
    //
    @media (max-width: 500px) {
      width: 80vw;
      height: 80vw;
    }
  }

}

.coverImageBlock {
  //background: #3b5998;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  //background: #1f455f;
  padding: 10px 0px 30px 0px;
  //height: 30vw;

  .coverImage{
    width: 60vw;

  }
}

.link-block {
  //background: #1f455f;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;


  .link-icon {
    width: 70px;
    height: 70px;
    //color: #666666 ;
    //filter: invert(50%) sepia(98%) saturate(12%) hue-rotate(314deg) brightness(100%) contrast(91%);
  }
}


.descriptionBlock{
  padding: 0px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //background: #1f455f;


  @media (min-width:1200px) {

  }

  @media (max-width:500px) {

  }


  .descriptionText{
    max-width: 600px;
    //background: #02BAA3;
    margin-bottom: 15px;
    //font-weight: lighter;
    line-height: 1.5;
    text-align: center;
  }
}




