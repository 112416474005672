@import "src/01_application/styles/colours";
@import "src/03_views/components_core/core_styles.module.scss";

.buttonFilled {
  background: $filledColour;
  color: $filledFontColour;
  border-style: solid;
  border-width: 1px;
  border-color: $filledColour;
  border-radius: $borderRadius;
  box-sizing: $boxSizing;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  font-weight: $fontWeight;
  line-height: $lineHeight;
  max-width: none;
  min-height:$minHeight;
  min-width: $minWidth;
  outline: none;
  overflow: hidden;
  padding: $padding;
  position: relative;
  text-align: center;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;


  &:hover {
    opacity: 0.80;
    transition: all .2s ease-out;
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }

  @media (max-width: 400px) {
    line-height: 1;
    min-height: 30px;
    width: 100%;
  }

}


