@import "src/01_application/styles/colours";

.beneficiary-profile-page{
  background: #F4F4F4;
  display: flex;
  min-height: 100vh;
  min-witdh: 100vw;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start;
}

.pin-input-container{
//background: #bc6767;
  margin-top: 50px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.indicator-block {
  //background: #1f455f;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

