@import "src/01_application/styles/colours";

.initiativeName {
  margin: 15px 0px 20px 0px;
  font-size: 1.8em;
  font-weight: bold;
}

.pricePerMeal {
  font-size: 1.5em;
  margin-top: 25px;
  font-weight: bold;
}

.stallName {
  font-size: 1em;
}

.stallAddress {
  font-size: 1em;
  color: #414141;
  margin-bottom: 25px;
}

.backBtnSection {
  //background: #155724;
  margin-bottom: 50px;
  margin-top: 10px;
}

.backBtn {
  display: flex;
  margin: 5px;
  border: 2px solid #e0e0e0;
  height: 40px;
  width: 90px;
  border-radius: 35px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-size: 1em;
  color: #9b9b9b;;


  &:hover {
    border: 4px solid black;
    height: 36px;
    width: 86px;
    color: black;
    transition: 0.35s
  }
}


.countSection {
  //background-color: #e40621;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
}

//.countContainer {
//  //background: #155724;
//  display: flex;
//  flex-direction: column;
//  border: 5px solid #000;
//  border-radius: 20px;
//  //margin: 10px;
//
//  @media (max-width: 460px) {
//    border: 2px solid #000;
//  }
//}
//
//.countContainerError{
//  border: 5px solid #e40621;
//}

.countInput {
  //background: #f1c40f;
  margin: 20px 20px 30px 20px;
  padding: -15px 0px;
  font-size: 5em;
  width: 200px;
  height: 100px;
  border: none;
  text-align: center;


  &:focus {
    outline: none;
  }

  @media (max-width: 660px) {
    width: 100px;
    height: 60px;
    font-size: 2.3em;

  }

  @media (max-width: 460px) {
    width: 80px;
    height: 40px;
    font-size: 2.3em;
  }

  @media (max-width: 400px) {
    width: 60px;
    height: 30px;
    font-size: 2.3em;
  }
}

.purchaseText {
  //background: #e40621;
  font-size: 2em;
  text-align: center;
  margin-bottom: -20px;
  margin-top: 10px;
  z-index: 3;

  @media (max-width: 460px) {
    font-size: 1.3em;
  }
}

.mealText {
  //background: #e40621;
  font-size: 2em;
  text-align: center;
  margin-top: -20px;
  margin-bottom: 20px;
  //width: 60%;
  //border-top: solid 2px #3e3e3e;

  @media (max-width: 460px) {
    font-size: 1.3em;
  }
}


.counterBtn {
  //background: #999999;
  background: $cta;
  margin: 30px;
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  //&:hover {
  //    width: 90px;
  //    height: 90px;
  //    margin:25px;
  //    transition: 0.35s
  //}
  //
  //&:active {
  //    margin: 30px;
  //    width: 80px;
  //    height: 80px;
  //}
  //


  .counterIndicator {
    //background: #e40621;
    text-align: center;
    font-size: 4rem;
    padding: 20px;
    color: #FFFFFF;

    @media (max-width: 460px) {
      font-size: 3rem;
      font-weight: bold;
      //padding: 10px;
    }
  }

  @media (max-width: 660px) {
    max-width: 50px;
    max-height: 50px;
    margin: 20px;
  }
}

.addtionalInformationBlock{
  background: #6a8aba;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.buttonSection {
  //background: #e40621;
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nextStepBtn {
  display: inline-flex;
  background: $cta;
  font-weight: bold;
  color: white;
  height: 50px;
  width: 200px;
  border-radius: 35px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 50px;
  //font-size: 2em;
}

.repeatPurchaseBlock{
  margin-top: 40px;
}

.labelText {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.subLabelText {
  margin-top: -4px;
  margin-bottom: 10px;
  color: #4C4C4C;
  font-size: smaller;
}


.radioOptionsContainer {
  //background: #155724;
  margin-bottom: 30px;


  .radioOption {
    //background: #00c3cf;
    margin-right: 10px;
    display: flex;
    flex-direction: row;

    .radioBtn{
      //background: #1f455f;
      padding: 0px 10px;
    }

    .radioOptionText {
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}
