@import "src/01_application/styles/colours";


.whyMealsCoverImageBlock {

  .whyMealsCoverImageTextBlock{
    display: flex;
    justify-content: center;
    align-items: center;
    //background: #00c3cf;
    height: 50vh;
    width: 100vw;
    position: absolute;


    .whyMealsCoverImageTextText{
      font-weight: bold;
      font-size: 12rem;
      //background: #8ab2b3;
      color: #FFFFFF;
      z-index: 0;
      padding: 0px 30px 0px 30px;
    }

  }

  .coverImageOverLay{
    background-color: rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 0;
    left: 0;
    height: 50vh;
    width: 100vw;
    z-index: 1;
  }


  .coverImage{
    height: 50vh;
    width: 100vw;
    object-fit: cover;
    z-index: 10;
  }

}




.titleBlock {
  margin: 30px 0px 30px 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  .titleBlock_text {
    font-size: 3rem;
    font-weight: bold;
  }
}


.paragraphBlock {
  margin: 10px 30px 0px 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  .paragraphBlock_text {
    max-width: 600px;
    margin-bottom: 15px;
    //font-weight: lighter;
    line-height: 1.5;
    text-align: center;
  }
}




.hordingDistribution_Container{
  display: flex;
  align-items: center;
  justify-content: center;
}


.hordingDistribution_Block{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  max-width: 1200px;
  //background: #4f7477;

  @media (min-width:1200px) {
    flex-direction: row;
  }

  @media (max-width:500px) {
    flex-direction: column;
  }

  .bodyImage{
    max-width: 300px;
    max-height: 300px;
    width: 50vw;
    height: 50vw;
    margin: 20px 40px 20px 40px;
    //border-radius: 50%;
    //box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);


    @media (min-width:1200px) {
      width: 50vw;
      height: 50vw;
    }

    @media (max-width:500px) {
      width: 80vw;
      height: 80vw;
    }
  }

  .hordingDistribution_TextBlock {
    max-width: 400px;
    padding: 0px 20px 0px 20px;

    .hordingDistribution_title {
      font-size: 3rem;
      font-weight: bold;


      @media (min-width: 1200px) {

      }

      @media (max-width: 500px) {
      }
    }

    .hordingDistribution_bodyText {
      margin-top: 20px;
      line-height: 1.5;
    }
  }


}



.footer{
  height:100px
}

