$finalHeight: 400px;
//$finalWidth: 3 * $finalHeight;
$finalWidth: 100vw;
$scrollBarHeight: 0px;

::-webkit-scrollbar {
  width: $scrollBarHeight;
  height: $scrollBarHeight;
}

::-webkit-scrollbar-button {
  width: $scrollBarHeight;
  height: $scrollBarHeight;
}


.horizontalScrollWrapper {
  //background: #fc7979;
  top: 0;
  left: 0;
  right: auto;
  width: calc(#{$finalHeight} + #{$scrollBarHeight});
  max-height: $finalWidth;
  padding-top: $scrollBarHeight;
  //overflow-y: auto;
  overflow-x: hidden;
  transform: rotate(-90deg) translateY(-$finalHeight);
  transform-origin: right top;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.itemWrapper {
  //background: #cab;
  transform: rotate(90deg);
  transform-origin: center;
  //transform-origin: right top;
  position: relative;
  height: $finalHeight;
  //squeeze cards
  margin: -40px 10px;
}

.item {
  width: 300px;
}


.container{
  //background: #ffd2d2;
  height: $finalHeight
  //height: $finalHeight
  //width: 100vw;
}

.loadingBlock {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;
}

.cardBlock {
  background: #ffffff;
  //width: 360px;
  width: 100%;
  height: $finalHeight;
  border-radius: 20px;
  //padding-bottom: 10px;
  //box-shadow: 10px 10px 50px #e0e0e0;
}

.cardTitle {
  //padding: 10px 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.titleText{
  margin: 5px 10px 5px 10px;
  color: #2f2f2f;
  font-size: large;
  font-weight: bold;
}

.cardDescription{
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.descriptionText{
  margin: 5px 10px 5px 10px;
  color: #404040;
  font-size: small;
}

.cardImage {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
