@import 'src/01_application/styles/colours';

.container {
  display: flex;
  flex-direction: column;

  //background: #00c3cf;
}

.logoImage{
  max-width: 100px;
  width: 20%;
  min-width: 60px;
  margin-right: 30px;
}


.subContainer {
  display: flex;
  //background: #e74c3c;
  align-items: center;
  justify-content: center;
  //width: 100%;
  //max-width: 1400px;
}

.content {
  //background: #3b5998;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1400px;

  @media (max-width: 700px) {
    flex-direction: column;
  }

}


.rightBlock {
  //background: #4cd964;
  flex: 0.47;
  display: flex;
  flex-direction: column;
  margin: 20px 30px;
  width: 100%;

  @media (max-width: 700px) {
    flex: 0.5;
    margin: 20px 0px;
  }

}


.leftBlock {
  //background: #e74c3c;
  width: 100%;
  flex: 0.47;
  display: flex;
  flex-direction: column;
  margin: 20px 30px;

  @media (max-width: 700px) {
    flex: 0.5;
    margin: 20px 0px;
  }

}

.verticalLine {
  width: 1px;
  height: 300px;
  background: $grey200;

  @media (max-width: 700px) {
    margin: 40px 0px 40px 0px;
    width: 90%;
    height: 1px;
  }

}

.header {
  font-size: 3rem;
  font-weight: bold;
}

.subHeader {
  font-size: 2rem;
  font-weight: bold;
}