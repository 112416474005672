@use 'sass:math';
@import "src/01_application/styles/const";
@import "src/01_application/styles/colours";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  //background: #f1c40f;
}

.subContainer {
  display: flex;
  width: 98%;
  //background: #ab2939;
  flex-direction: column;
}

.headingText {
  font-size: 4rem;
  font-weight: bold;
  //background: #787878;
  width: 90%;
  max-width: 1000px;
  margin: 50px 0px 0px 0px;
}

.heading2Text {
  font-size: 3rem;
  margin: 20px 0px 10px 0px;
  //font-weight: bold;
}


.hello {
  //unused
  background: #FCE205;
}


.paymentMethodBtn {
  background: #FFFFFF;
  color: #313131;
  display: flex;
  justify-content: center;
  align-items: center;
  //font-weight: bold;
  height: 90px;
  width: 50%;
  max-width: 400px;
  border-radius: 10px;
  margin-top: 20px;
  border: none;
  border: 1px #676767 solid;
  font-weight: bold;
  font-size: 1.9rem;


  @media (max-width: $mobileMenuBreakPoint) {
    width: 90%;
  }

  &:hover {
    font-weight: bold;
    border: 2px #676767 solid;
    transition: all .1s ease-out;
    color: #2a2a2a;
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }
}

.highlight {
  border: 3px $grey900 solid;
}

.formBlock {
  //background: #ab2939;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 500px;
  z-index: -1;
}

.cardSupplementaryInfoBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.expirationBlock {
  flex: 0.6
}

.cvvBlock {
  flex: 0.20
}


.spacer {
  height: 10px;
}

.cardsBlock{
  width: 100vw;
  margin-top: 5px;
  //background: #FCE205;
  margin-left: -40px;
}


.formField {
  padding: 12px 10px;
  //font-size: large;
  //background: #bc6767;
  width: 100%;
  //height: 56px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  position: relative;
  transition: 0.3s all;
  margin-top: 15px;

  &:focus {
    border: 3px solid #000000;
    outline: none;

  }

  &__error {
    border: 3px solid #e40621;
  }
}

.cancelBlock {
  //background: #4c4c4c;
  display: flex;
  justify-content: flex-end;

  animation-name: fade-in;
  animation-fill-mode: both;
  animation-duration: 0.8s;
}

$animationDelay: 1;
@for $i from 1 through 15 {
  .projects div:nth-of-type(#{$i}) {
    animation-delay: #{0.3+ math.div($i, 30)}s;
  }
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}


.cancelIcon {
  position: absolute;
  width: 30px;
  height: 30px;

  &:hover {
    width: 35px;
    height: 35px;

    transition: all .1s ease-out;
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }
}

.loading {
  //background: yellow;
  width: 30%;
  display: grid;
  place-items: center;
  margin: 100px 0px;
}

.cardBlock {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.cardList {
  display: flex;
  flex-wrap: wrap;
}

.card {
  background: #FFFFFF;
  box-shadow: 5px 20px 80px rgba(49, 49, 49, 0.17);
  border-radius: 10px;
  width: 300px;
  min-height: 180px;
  margin: 10px 10px;
  font-weight: bold;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }

  @media (max-width: 360px) {
    min-height: 110px;
    width: 270px;
  }
}

.selected {
  border: solid 3px $cta;
}

.cardDetailsBlock {

  //background: #185c53;
  display: flex;
  flex-direction: column;
  margin: 0px 40px;
  padding: 20px 0px;
  color: #4c4c4c;
}


.addCardBlock {
  height: 100%;
  //background: #185c53;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #4c4c4c;
  //font-weight: bold;
}

.addCardText {
  //color: #4c4c4c;
  //font-weight: bold;
}

.plusIcon {
  width: 50px;
  margin: 10px 20px 10px 20px;
}


.priBtnContainer {
  background: $cta;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  //font-weight: bold;
  height: 50px;
  width: 50%;
  border-radius: 10px;
  margin: 50px 0px 100px 0px;
  border: none;

  @media (max-width: $mobileMenuBreakPoint) {
    width: 90%;
  }

  &:hover {
    font-weight: bold;
    transition: all .1s ease-out;
    color: #FFFFFF;
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }
}


.buttonDisabled {
  background: #d7d7d7;

  &:hover {
    //font-weight: bold;
    transition: all .1s ease-out;
    color: #FFFFFF;
    cursor: not-allowed;
  }

}