
.card {
  display: flex;
  background: #FFFFFF;
  max-width: 400px;
  width: 45%;
  margin: 40px 0px 40px 20px;

  @media (max-width: 550px) {
    width: 95%;
    margin: 30px 0px;
    //background: #37b9a2;
  }

}

.cardDetails {
  flex-direction: column;
  width: 95%;
  margin: 30px 20px;
  //background: #f1c40f;
}


.headingText {
  margin:10px 0px;
  font-weight: bold;
  font-size: 1.7em;
}

.subHeadingText{
  color: #4c4c4c;
  margin: 20px 0px;
}