@import "src/01_application/styles/const";
@import "src/01_application/styles/colours";

.visaIcon {
  width: 50px;
  height: 35px;
  margin-bottom: 50px;

  @media (max-width: 390px) {
    width: 40px;
    height: 35px;
    margin-bottom: 25px;
  }
}

.masterCardIcon {
  width: 70px;
  height: 55px;
  margin-bottom: 45px;

  @media (max-width: 390px) {
    width: 70px;
    height: 35px;
    margin-bottom: 35px;
  }
}

.amexIcon {
  width: 70px;
  height: 65px;
  margin-left: -10px;
  margin-bottom: 30px;

  @media (max-width: 390px) {
    width: 50px;
    height: 55px;
    margin-left: -10px;
    margin-bottom: 20px;
  }
}

.card {
  background: #FFFFFF;
  box-shadow: 5px 15px 40px rgba(49, 49, 49, 0.17);
  border-radius: 10px;
  width: 280px;
  height: 170px;
  margin: 10px 10px;
  font-weight: bold;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
    z-index: 10;
  }

  @media (max-width: 390px) {
    margin: 5px 10px;
    width: 220px;
    height: 130px;
  }
}

.selected {
  border: solid 3px $grey900;
  margin: 7px 7px;
}

.cardDetailsBlock {
  //background: #185c53;
  display: flex;
  flex-direction: column;
  margin: 0px 30px;
  //padding: 20px 0px;
  color: #4c4c4c;

  @media (max-width: 390px) {
    margin: 0px 25px;
    //padding: 5px 0px;
  }
}