@import "src/01_application/styles/colours";

.header {
  position: fixed;
  top: 0;
  z-index: 20;
  width: 100%;
  padding: 10px 30px 5px 30px;
  background: #fff;
  //background: #90b9d4;
  //border-bottom: solid 1px #fff;
  transition: padding-top 0.3s ease, padding-bottom 0.3s ease;
  will-change: padding-top, padding-bottom;
  //background: #e40621;
  display: flex;

  @media (max-width: 1440px) {
    padding: 10px 20px;
  }

  @media (max-width: 992px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 0;
    //background: #fff;
    //background: #3b5998;
  }

  &--condensed {
    @media (max-width: 1440px) {
      padding: 5px 20px;
    }

    @media (min-width: 993px) {
      height: 20px;
      padding: 5px 0;
      //padding-bottom: 0px;
    }
  }


  //&__logo,
  //&__navs {
  //    transition: all 0.3s ease;
  //}

  //.secondmeal_text_container_mobile {
  //    @media (max-width: 992px) {
  //        margin-left: 58px;
  //        background: #1f455f;
  //    }
  //}

  //&__logo {
  //  width: 100px;
  //  height: 100px;
  //    background: #C5C5C5;
  //
  //  @media (max-width:992px) {
  //    width: 48px;
  //    height: 48px;
  //      background: #155724;
  //  }
  //}


  //&--condensed &__logo {
  //    @media (min-width: 993px) {
  //        width: 65px;
  //        height: 65px;
  //    }
  //
  //}

  //&--condensed &__navs {
  //    @media (min-width: 993px) {
  //        width: calc(100% - 65px);
  //    }
  //
  //}
  //
  //&__book {
  //    position: absolute;
  //    top: calc(50% - 16px);
  //    right: 20px;
  //    width: 184px;
  //    height: 48px;
  //    font-size: 1.4rem;
  //
  //    @media (max-width: 992px) {
  //        top: calc(50% - 20px);
  //        right: 20px;
  //        left: auto;
  //        width: 120px;
  //        height: 40px;
  //    }
  //
  //}
  //
  //&--condensed &__book {
  //    @media (min-width: 993px) {
  //        top: calc(50% - 8px);
  //        height: 42px;
  //    }
  //
  //}

}

.secondmeal-text {
  font-size: x-large;
  font-weight: bolder;
  //background: #bc6767;
  color: #000000;

  @media (max-width: 992px) {
    margin-left: 58px;
    //background: #1f455f;
  }
}


.navigation_items_container {
  width: calc(100% - 200px);
  //background: #00c3cf;
  display: flex;
  justify-content: flex-end;
  //flex-direction: column;


  @media (max-width: 992px) {
    position: absolute;
    top: 50px;
    left: 0;
    display: none;
    width: 70%;
    min-width: 400px;
    height: 100vh;
    //height: calc(100vh - 80px);
    padding: 0;
    background: #fff;
    //background: #155724;

    &--drawer-active {
      //z-index: -10;
      //position: absolute;
      display: block;
      //background: #bc6767;
      //transition: all 0.3s ease;
      animation: fade-in 0.3s linear, from-top 0.3s ease-out;
    }

  }

}

.loginBlock {
  //background: #f1c40f;
  //text-align: center;
  vertical-align: center;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 50px;
  line-height: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 992px) {
    width: calc(100% - 100px);
    margin-right: 20px;
  }
}

.loginSubBLock {
  //background: #1f455f;
  width: 55px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;


}

.profileIcon {
  width: 24px;
  height: 24px;
  &:hover {
    cursor: pointer;
  }

}
