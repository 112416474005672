@import "src/01_application/styles/const";
@import "src/01_application/styles/colours";

.container{
  margin-top: 90px;
  width: 90%;
  max-width: 800px;
}

.logoImage{
  max-width: 100px;
  width: 20%;
  min-width: 60px;
  margin-right: 30px;
}

.headingText{
  font-size: 4rem;
  font-weight: bold;
}


.heading3Text{
  font-weight: bold;
  font-size: 1.7em;
}

.subheadingText {
  //font-size: smaller;
  color: #5e5e5e;
  margin: 30px 0px 10px 0px;
}

.formBlock {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 600px;
  z-index: 0;
  margin-bottom: 40px;
}


.formField {
  padding: 12px 10px;
  //font-size: large;
  //background: #bc6767;
  //width: 90%;
  //height: 56px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  position: relative;
  transition: 0.3s all;
  margin-top: 15px;

  &:focus {
    border: 3px solid #000000;
    outline: none;

  }

  &__error {
    border: 3px solid #e40621;
  }
}

.signInBlock{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.priBtn{
  background: $cta;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 50px;
  width: 45%;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }
}

.auxLinksBlock{
  display: flex;
  //background: #1f455f;
  justify-content: space-between;
  max-width: 600px;
  width: 90%;
}

.secBtn{
  background: #ececec;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  //font-weight: bold;
  height: 50px;
  width: 45%;
  border-radius: 10px;
}

.createAccount{
  margin-top: 10px;
}