.dropzoneBlock{
  margin: 30px 0px 30px 0px;
}

.formLabel{
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.formSubLabel{
  margin-top: -4px;
  margin-bottom: 10px;
  color: #4C4C4C;
  font-size: smaller;
}

.plusIconBlock{
  //background: #f1c40f;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plusIcon{
  width: 50px;
  margin: 10px 0px;
}

.checkIcon {

}

.fileName {
  color: #4c4c4c;
  display: flex;
  flex-direction: row;
}


.loadingContainer{
  margin: 0px 0px 0px 10px;
}

.fileItem {
  margin-bottom: 5px;
  font-size: larger;
}

.dropzoneInstructionText{
  margin: 10px 10px 10px 10px;
}

.profilePicExplain {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  max-width: 400px;
  color: #4c4c4c;
  text-align: center;
  font-size: smaller;

}


.dropzone{
  padding: 10px 10px;
  font-size: smaller;
  //background: #bc6767;
  width: 90%;
  //height: 56px;
  border: 4px solid #e0e0e0;
  border-radius: 15px;
  position: relative;
  transition: 0.3s all;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fileItemsBlock {
  //background: #155724;
  width: 100%;
  margin: 10px 0px 20px 0px;
}
