.page-container{
  text-align: center;
  //background: #1f455f;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 100px;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 30px;
  padding: 0px 50px;
  max-width: 400px;
}

.sub-title {
  color: #666666;
  padding: 0px 50px;
  max-width: 400px;
}
