@import "src/01_application/styles/colours";

// configure this file to style to style the core components in the project
// style the components from this file rather than the individual components

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////// FIELDS
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

$textInputColour: $grey900;
$textLabelColour: $grey700;

$fieldPadding: 1rem 1rem;

$fieldBorderRadius: 8px;

$fieldBorderWidth: 3px;
$fieldBorderColour: $grey300;

$fieldBorderWidth_focus: 3px;
$fieldBorderColour_focus: $grey900;


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////// BUTTON
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

$filledColour: $grey800;
$outlineColour: $grey800;
$secondaryColour: $grey200;

$filledFontColour: $grey100;
$outlineFontColour: $grey900;
$secondaryFontColour: $grey900;

$outlineBorderWidth: 2px;


$borderRadius: 5px;
$boxSizing: border-box;
$fontWeight: 700;
$lineHeight: 1.5;
$minHeight: 38px;
$minWidth: 120px;
$padding: 9px 20px 8px