.pageContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.bottomSpacer {
  height: 100px
}

