@import "src/01_application/styles/colours";

.Paragraph {
  font-size: small;
}

.ParagraphLabel {
  font-size: small;
  color: $grey600;
}

.ParagraphSemiBold {
  font-size: small;
  font-weight: bolder;
}

.SmallText {
  font-size: smaller;
}

.SmallTextSemiBold {
  font-size: smaller;
  font-weight: bolder;
}

.SmallTextLabel {
  font-size: smaller;
  color: $grey600;
}

.TinyText {
  font-size: 0.6em;
}

.TinyTextSemiBold {
  font-size: 0.6em;
  font-weight: bolder;
}

.TinyTextLabel {
  font-size: 0.6em;
  color: $grey600;
}
