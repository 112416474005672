@import "src/01_application/styles/colours";

.block {
  background: #F4F4F4;
  //background: #f1c40f;
  padding-top: 100px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}


.receipt {
  //background: #1f455f;
  //height: 500px;
  display: flex;
  width: 70%;
  max-width: 700px;
  flex-direction: column;
  background: #F9F9F9;
  //background: #3b5998;
  margin: 10px 40px 0px 40px;
  border-radius: 40px;
  box-shadow: 10px 10px 50px #e0e0e0;
  align-self: center;

  @media (max-width: 600px) {
    margin: 0px 15px 0px 15px;
    align-self: center;
    //padding: 20px;
    width: 90%;
  }

  .receiptHeader {
    background: $receiptHeaderColour;
    height: 150px;
    width: 100%;
    padding: 0px;
    max-width: 700px;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 700px;
    align-self: center;
    border-top-right-radius: 40px;;
    border-top-left-radius: 40px;;

    //@media (max-width:550px) {
    //    margin: 0px 15px 0px 15px;
    //    align-self: center;
    //    padding: 20px;
    //    width: 90%;
    //}


    .headerLogo {
      width: 60px;
    }

    .headerText {
      margin-top: 10px;
      font-weight: bold;
      color: white;
    }
  }
}


.detailsSection {
  padding: 50px;
  flex-direction: column;
  //background: #bc6767;

  @media (max-width: 550px) {
    margin: 0px 15px 0px 15px;
    align-self: center;
    padding: 40px 20px 60px 20px;
    width: 90%;
  }
}

.stopAutoRecurringContainer {
  display: grid;
  place-items: end;
}


.stopAutoRecurringBtn {
  //border: 1px solid #750000;
  color: #750000;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  text-align: right;


  &:hover {
    color: #750000;
    //font-weight: bold;
    //transition: 0.3s all;
  }

  //background: #f1c40f;

}


.horizontalRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0px 5px 0px;

  .leftBlock{
    flex: 0.5
  }
  .rightBlock{
    flex:0.5
  }
}

.cancelBtnContainer {
  display: grid;
  place-items: start;
}


.cancelBtn {
  border: 1px solid #9b9b9b;

  color: #9b9b9b;
  margin-bottom: 20px;
  padding: 10px 25px;
  border-radius: 20px;


  &:hover {
    border: 3px solid #4a4a4a;
    font-weight: bold;
    color: #4a4a4a;
    //font-weight: bold;
    transition: 0.3s all;
  }

  //background: #f1c40f;

}


.greetings {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.subGreetings {
  color: #4c4c4c;
  margin-bottom: 30px;
}

.line {
  height: 1px;
  background: #9b9b9b;
  width: 90%;
  margin-bottom: 20px;
}

.row {
  margin: 40px 0px 40px 0px;

  .dateStyle {
    margin-bottom: 10px;
    font-weight: bold;
    color: #4c4c4c;
  }

  .horizontalRowDetals {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 0px 5px 0px;

    .leftBlock{
      flex: 0.8
    }
    .rightBlock{
      flex:0.2
    }
  }

  .pricing {
    //font-size: 3rem;
    font-weight: bold;
    padding-left: 10px;
  }

  .pricingLarge {
    //background: #00c3cf;
    font-size: 3rem;
    font-weight: bold;
    padding-left: 10px;
    text-align: right;
  }

  .typeBlock{
    //margin: 10px 0px 5px 0px;
    text-align: right;
    //background: #00c3cf;

  }
  .descriptionText {
    //margin-top: 5px;
    color: #9b9b9b;
    text-align: right;
    padding-left: 10px;
    //background: #00c3cf;
  }

  .viewAssociatedPurchase{
    //background: $cta;
    //color: #FFFFFF;
    margin: 10px 0px 10px 0px;
    padding: 5px 20px;
    border-radius: 5px;
    border: 1px solid $cta;
    text-align: center;
  }
}


.emptyStateBlock {
  //background: #bc6767;
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .plateIcon {
    width: 80px;
    height: 80px;
    margin-bottom: 30px;
  }

  .emptyStateText {
    max-width: 400px;
    text-align: center;
  }
}

.confirmationHeader {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.confirmationSubHeader {
  color: #4c4c4c;
}

.buttonContainer {
  margin: 50px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;


  .backBtn {
    background: $cta;
    color: #ffffff;
    padding: 15px 40px;
    border-radius: 25px;
    font-weight: bold;
    font-size: larger;
  }

  .ProceedBtn {
    color: #2f2f2f;
    border: 0px;
    background: #F4F4F4;

    //&:hover{
    //  //border: 3px solid #4a4a4a;
    //  //font-weight: bold;
    //  color: #2f2f2f;
    //  //font-weight: bold;
    //  transition: 0.3s all;
    //}
  }


}


.formLabel {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 40px;
}

.form-subLabel {
  margin-top: -4px;
  margin-bottom: 10px;
  color: #4C4C4C;
  font-size: smaller;
}

.formField {
  padding: 12px 10px;
  font-size: large;
  //background: #bc6767;
  width: 90%;
  //height: 56px;
  border: 4px solid #e0e0e0;
  border-radius: 15px;
  position: relative;
  transition: 0.3s all;
  font-family: 'Arial', Sans-Serif;


  &:focus {
    border: 4px solid #000000;
    outline: none;

  }

  &__error {
    border: 5px solid #e40621;
  }
}

.errorBlock {
  margin-top: 5px;
  background: #f1c40f;
}
