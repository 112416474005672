@use 'sass:math';
@import "../../../01_application/styles/const";

.loading {
  //background: #f1c40f;
  width: 100%;
  display: grid;
  place-items: center;
  margin: 100px 0px;
}

.container{
  display: flex;
  flex-direction: row;
  //width: 100%;
  //background: #185c53;

  @media (max-width: $mobileMenuBreakPoint) {
    //@media (max-width: 900px) {
    width: 100vw;
  }

}

.carouselContainer {
  display: none;

  @media (max-width: 820px) {
    display: flex;
    //background: #37b9a2;
    width: 100vw;
    height: 300px;
  }
}

.cardListContainer{
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 820px) {
    display: none;
  }
}

.cardBlock{
  //background: #37b9a2;
  width: 100%;
}

.addCardBlock {
  width: 100%;
  height: 100%;
  //background: #185c53;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #4c4c4c;
  //font-weight: bold;
}

.addCardText {
  //color: #4c4c4c;
  //font-weight: bold;
}

.plusIcon {
  width: 50px;
  margin: 10px 20px 10px -35px;
}

.cardList{
  //background: #FCE205;
  width: 90%;
  max-width: 870px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 820px) {
    //display: none;
    display: flex;
    width: 100%;
    height: 250px;
  }
}

//.carouselContainer {
//  //background: #37b9a2;
//  display: flex;
//  width: 100%;
//  height: 250px;
//
//  @media (min-width: 820px) {
//    display: none;
//  }
//}

//.freeSwipe {
//  list-style: none;
//  padding: 0;
//  margin: 0;
//  display: flex;
//  flex-direction: row;
//  position: relative;
//  transform-style: preserve-3d;
//  -webkit-backface-visibility: hidden;
//  backface-visibility: hidden;
//  will-change: transform, transition;
//  //overflow-y: scroll !important;
//}

.carouselItem{
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  //background: #37b9a2;
  //width: 300px;
  //padding: 10px;
  //margin: 10px ;
  //margin-top: -10px;

  @media (max-width: 390px) {
    height: 200px;
  }
}


.cancelBlock {
  //background: #4c4c4c;
  display: flex;
  justify-content: flex-end;

  animation-name: fade-in;
  animation-fill-mode: both;
  animation-duration: 0.8s;
}

$animationDelay: 1;
@for $i from 1 through 15 {
  .projects div:nth-of-type(#{$i}) {
    animation-delay: #{0.3+ math.div($i, 30)}s;
  }
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}


.cancelIcon {
  position: absolute;
  width: 30px;
  height: 30px;
  z-index: 10;

  &:hover {
    width: 35px;
    height: 35px;

    transition: all .1s ease-out;
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }
}
