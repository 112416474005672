@import "src/01_application/styles/colours";

.progress-bar-block {
  margin-top: 10px;

  .stats{
    //background: #bc6767;
    margin: 0px 20px 0px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .fontSize {
      font-size: smaller;
    }
  }
  .progress-bar {
    background: #e7e7e7;
    height: 5px;
    margin: 15px 20px 10px 20px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .low{
    color: $healthStatus_low;
    font-weight: bold;
  }

  .average{
    color: $healthStatus_average;
    font-weight: bold;
  }

  .healthy{
    color: $healthStatus_healthy;
    font-weight: bold;
  }
}
