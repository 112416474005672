@import "src/01_application/styles/colours";

.container {
  margin-top: 30px;
  margin-bottom: 50px;
}

.informationBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  color: #3e3e3e;
}

.notesBlock {
  margin-top: 5px;
  color: #3e3e3e;
}

.noteInformationBlock {
  padding: 20px;
  margin-top: 2px;
  background: #eeeeee;
  width: 85%;
  border: none;
  font-family: "Orator Std", Sans-Serif;
}

.buttonBlock {
  display: flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;

}

.editBtn {
  border: 2px solid #4c4c4c;
  padding: 10px 40px;
  border-radius: 30px;
  color: #3e3e3e;
}

.createBtn {
  background: $cta;
  padding: 10px 40px;
  color: #FFFFFF;
  border-radius: 30px;
  font-weight: bold;
}
