//base rules
//target only element selectors, no classes or ids!
@charset "utf-8";
@import 'src/01_application/styles/colours';
@import url('https://fonts.googleapis.com/css?family=Lora|Montserrat:wght@200,500,700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=');

/* Firefox */
@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: light;
  }
}

/* Chrome */
@media (forced-colors: active) {
  :root {
    color-scheme: light;
  }
}

html,
body {
    width: 100%;
    height: 100%;
    padding:0;
    margin:0;
}


html {
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -ms-overflow-style: scrollbar;

    &.disable-scroll {
        overflow-y: hidden;
    }

    @media (max-width:1200px) {
        font-size: 60%;
    }

    @media (max-width:1000px) {
        font-size: 55%;
    }

    @media (max-width:360px) {
        font-size: 43%;
    }

    @media (max-width:767px) {
        &.disable-scroll-mobile {
            overflow-y: hidden;
        }

    }

}

textarea,
body {
    color: #000;
    font-family: 'San Francisco', Sans-Serif;
    font-size: 1.6rem;
}

*::selection {
    color: #FFF;
    background: $cta;
}

label {
    font-weight: normal;
    cursor: pointer;
}

a {
    display: inline-block;
    color: $link;
    text-decoration: none;
    transition: all .3s ease-out;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;

    /*
      Introduced in IE 10.
      See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
    */
    -ms-user-select: none;
    user-select: none;


    &:hover,
    &:active,
    &:focus {
        color: $cta;
        text-decoration: none;
    }

    &:active {
        transform: translateY(1px);
        transition-duration: .05s;
    }
}

//p,
//h1,
//h2,
//h3,
//h4,
//h5
//{
//    margin: 0;
//    font-family: 'Arial', Sans-Serif;
//    font-weight: 400;
//}

input,
textarea {
    caret-color: $cta;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
}

ul {
    padding-left: 0px;
    list-style-type: none;
}


disable_text_highlighting {
    -webkit-user-select: none; /* Webkit */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;     /* IE 10  */
    /* Currently not supported in Opera but will be soon */
    -o-user-select: none;
    user-select: none;
}
