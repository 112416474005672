@import "src/01_application/styles/colours";

.container{
  width: 100vw;
  margin-bottom: 40px;
}

.initiativeStatsHero {
  margin-top: 30px;
  min-height: 80vh;
  background: $cardColour;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //background: #00c3cf;
  //padding: 15px 100px 20px 100px;
  //padding-left: 20px;
  //padding-right: 20px;
  box-shadow: 10px 10px 50px #e0e0e0;
  //border: solid 3px #000000;
  //border-left: solid 3px #000000;
  //border-right: solid 3px #000000;

  @media (min-width: 1200px) {
    min-height: 90vh;
    margin-top: 30px;
  }

  @media (max-width: 400px) {
    margin-top: 90px;
    min-height: 140vh;
    padding: 5px 30px 20px 30px;
  }

  @media (max-width: 570px) {
    margin-top: 90px;
    min-height: 90vh;
    padding: 5px 30px 20px 30px;
  }



  .heroText {
    //color: white;
    //color:black;
    color: #000000;
    max-width: 700px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 5rem;
    //background: #155724;
    text-align: center;

    @media (max-width: 400px) {
      font-size: 3rem;
    }
  }

  .initiativeStallsHeaderProgressBar {
    width: 90vw;
    max-width: 400px
  }

  .backBtnSection {
    //background: #155724;
    margin-bottom: 20px;
    margin-top: 10px;
    display: flex;
    width: 80vw;
    max-width: 800px;


    .backBtn {
      display: flex;
      margin: 5px;
      border: 2px solid #e0e0e0;
      height: 40px;
      width: 90px;
      border-radius: 35px;
      text-align: center;
      justify-content: center;
      align-items: center;
      align-self: center;
      font-size: 1em;
      color: #9b9b9b;;


      &:hover {
        border: 4px solid black;
        height: 36px;
        width: 86px;
        color: black;
        transition: 0.35s
      }
    }
  }

  .viewDetails {
    margin: 30px 0px;
  }

  .generalFundCta {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 200px;
    height: 70px;
    background: $cta;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;


    .generalFundCtaText {
      color: #ffffff;
      font-weight: bold;
    }
  }

  .bold {
    font-weight: bold;
  }

  .status6Description {
    //max-width: 500px;
    //margin-top: -60px;
    width: 100vw;
    color: #4C4C4C;
    background: #f1c40f;
    //padding: 10px 70px 10px 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    //@media (max-width:500px) {
    //  //margin-top: 20px;
    //}

    @media (max-width: 400px) {
      margin-top: -80px;
    }

    .status6DescriptionText {
      margin: 10px 0px;
      padding: 30px 10px 30px 10px;
      max-width: 600px;
    }
  }

  .initiativeDescriptionText {
    color: #4C4C4C;
    max-width: 600px;
  }

  .gmfSuspendedCount {
    margin-top: 20px;
    font-size: 10rem;
    font-weight: bold;
  }

  .heroParagraphText {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 15px;
    color: #4C4C4C;
    max-width: 600px;

    &__bold {
      font-size: 2.3rem;
      padding: 10px;
      margin-top: 20px;
      color: white;
      font-weight: bold;
      border-left: solid 15px #FFC919;
    }
  }

  &__logo {
    margin-top: 50px;
    width: 25vw;
    max-width: 120px;
    height: 25vh;
    max-height: 120px;
  }
}

.imageContainer{
  margin: 40px 0px 0px 0px;
  max-width: 600px;
  //background: #f1c40f;
}
.image {
  border-radius: 10px;
  width: 100%;
}
