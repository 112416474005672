

@import "src/01_application/styles/colours";


.header-container {

  background: $beneficiaryProfileHeaderColour;
  height: 45vh;
  min-height: 400px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .date-block {
    margin-top: 120px;
    width: 70vw;
    max-width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .date {
      color: $beneficiary-name;
    }
  }

  .profile-picture {
    margin-top: 20px;
    width: 40vw;
    height: 40vw;
    max-width: 120px;
    max-height: 120px;
    border-radius: 15vh;
  }

  .profile-name {
    margin-top: 20px;
    margin-bottom: 20px;
     font-weight: bold;
    color: $beneficiary-name;
    font-size: 1.4em;
  }

  .supplementary-info-block{

    margin-bottom: 20px;

    .supplementary-info{
      display: flex;
      flex-direction: row;
      width: 70vw;
      max-width: 400px;
      justify-content: space-between;

      .key {
        color: $beneficiary-name;
      }

      .value{
        color: $beneficiary-name;
      }
    }
  }
  }

.instructions-block {
  max-width: 400px;
  text-align: center;
  margin-top: 30px;
  color: #666666;
  padding: 0px 10px;
}



.qr-code-block{
  display: flex;
  //height: 40vh;
  //max-height: 300px;
  //background: #bc6767;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;


}
