.container{
  width: 100%;
}

.heading2Text {
  font-size: 2rem;
  margin: 20px 0px 10px 0px;
  font-weight: bold;
  //font-weight: bold;
}

.hidden {
  display: none;
}