@import "../../../01_application/styles/colours";
@import "../../../01_application/styles/const";

.modalContainer {
  background: $darkTransparentScreen;
  //background: #0a66b7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh + 60px);
  z-index: 1500 !important;
  min-height: calc(100vh + 60px);
  margin-top: -$headerHeight;
  overflow-y: scroll;
  //overflow-y: hidden;

  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

.noAnimation {
  animation-duration: 0s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.modalCard {
  background: $grey000;
  padding: 10px 20px;
  //min-height: 200px;
  //margin-top: 10px;
  //margin: -10vh 5px 0;
  width: 80%;
  max-width: 950px;
  max-height: 90vh;
  overflow-y: scroll;
  display: block;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;

  @media (max-height: 700px) {
    //margin-top: 5vh;
  }

  @media (max-width: 700px) {
    //margin: -10vh 5px 0;
    width: 90%;
    //height: 700px;
  }

  @media (max-width: 450px) {
    //margin: -10vh 5px 0;
    width: 85%;
    //height: 700px;
  }

}

.headerButtonBlock {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  //background: #185c53;

  .icon {
    margin-top: 15px;
    margin-right: 10px;
    width: 20px;
    height: 20px;

    &:hover {
      cursor: pointer;
    }
  }
}
