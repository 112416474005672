@import "src/01_application/styles/const";
@import "src/01_application/styles/colours";

.form-block {
  //background: #155724;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90vw;
  max-width: 500px;
  margin-bottom: 130px;
  //background: #f1c40f;
}

.form-label {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 40px;
}

.form-subLabel {
  margin-top: -4px;
  margin-bottom: 10px;
  color: #4C4C4C;
  font-size: smaller;
}

.DOBBlock {
  display: flex;
  flex-direction: row;
  //justify-content: space-between;
}

.radioHorizontalOptionsBlock {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.radioVerticalOptionsBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}


.radioHorizontalOptionBlock {
  margin-right: 30px;
}

.buttonContentSubBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.DD {
  width: 30px !important;
  margin-right: 10px;
  //background: #00c3cf;
}

.MM {
  width: 30px !important;
  margin-right: 10px;
  //background: #00c3cf;
}


.YYYY {
  width: 60px !important;
  margin-right: 10px;
  //background: #00c3cf;
}

.form-field {
  padding: 12px 10px;
  font-size: large;
  //background: #bc6767;
  width: 90%;
  //height: 56px;
  border: 4px solid #e0e0e0;
  border-radius: 15px;
  position: relative;
  transition: 0.3s all;
  font-family: 'Arial', Sans-Serif;


  &:focus {
    border: 4px solid #000000;
    outline: none;

  }

  &__error {
    border: 5px solid #e40621;
  }
}

.error-block {
  margin-top: 5px;
  background: #f1c40f;
}

.submit-button {
  margin-top: 10px;
  display: inline-flex;
  background: $cta;
  color: white;
  height: 70px;
  width: 350px;
  border-radius: 35px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-size: 1.5rem;
  border-width: 0px;
}

.buttons {
  display: flex;
  flex-direction: column;
}
