.landingContainer {
  background: #F4F4F4;
}

.stallCardsCollection {
  display: flex;
  justify-content: center;
  align-items: center;
  //background: #e40621;

  .stallCardContainer {
    //background: #155724;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
  }
}

.initiativeStallStatsLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  //padding-top: 100px;
  height: 100vh
}

.boldInitiativeName {
  font-weight: bold;
}

.emptyStateBlock {
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .plateIcon {
    width: 80px;
    height: 80px;
    margin-bottom: 30px;
  }

}

.stallStatsInitiativeHeader {
  text-align: center;
  padding-top: 40px;
  font-size: 5rem;
  font-weight: bold;
}

.myMasonryGrid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  max-width: 1280px;
  width: auto;
  //margin: auto;
  margin-top: 30px;
  //background: #74B8AE;
  padding: 30px;
  flex: 1;
  //align-items: center;
  //justify-content: center;
}

.myMasonryGridColumn {
  //display: flex;
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
  //background: #bc6767;
  align-items: center;
  width: auto;
}

// Style your items
.myMasonryGridColumn > div { /* change div to reference your elements you put in <Masonry> */
  //background: grey;
  //margin-bottom: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}


//----


