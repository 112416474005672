@import "src/01_application/styles/const";
@import "src/01_application/styles/colours";


.failed {
  background: $cherry-red;

  border-radius: 10px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 15px;
}

.refunded {
  background: $healthStatus_average;
  padding: 2px 15px;
  border-radius: 10px;
  color: $grey800;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tagText{
  padding: 4px 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
}