
.hero {
  min-height: 80vh;
  background: #02BAA3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //padding: 15px 100px 20px 100px;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 1200px) {
    min-height: 80vh;
    margin-top: 30px;
  }

  @media (max-width: 500px) {
    min-height: 80vh;
    padding: 5px 30px 20px 30px;
  }

}


.headerLinkText{
  color: #ffffff;

  &:hover {
    //border: 4px solid black;
    color: black;
    transition: 0.35s
  }
}

.progressBarBlock {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  max-width: 800px;

  .progressBarIndicatorBlock {
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //background: #9b9b9b;

  }

  .progressBar {
    background: #e7e7e7;
    //background: #f1c40f;
    height: 20px;
    margin: 15px 20px 10px 20px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}
.progressBarMealCount{
//position: absolute;
//  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
margin-top: -18px;
  width: 100%;
  //color: #4a4a4a;
  //background: #4c4c4c;
}


.logoBlock {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.quantedgeLogo {
  margin-top: 50px;
  width: 50vw;
  max-width: 320px;
  height: 25vh;
  max-height: 120px;
  object-fit: contain;
  margin-right: 50px;
}


.logo {
  margin-top: 100px;
  width: 25vw;
  max-width: 120px;
  height: 25vh;
  max-height: 120px;
}

.headerTextCount{
  color: #f1c40f;
}


.heroText {
  //color: white;
  //color:black;
  color: #f3f3f3;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 5rem;
  //background: #155724;
  text-align: center;
  max-width: 800px;
}

.heroParagraphText {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 15px;
  color: #f3f3f3;
  max-width: 600px;
}

.heroParagraphTextAccent {
  font-size: 2.3rem;
  padding: 10px;
  margin-top: 20px;
  color: white;
  font-weight: bold;
  border-left: solid 15px #FFC919;
}



.callToActionBlock{
  margin-top: 30px;
  background: #303e48;
  //background: #00c3cf;
  color: white;
  font-weight: bold;

  border-radius: 30px;
  margin-bottom: 40px;


  :hover{
    cursor: pointer;
    font-weight: bold;
  }

  :active{
    transform: translateY(1px);
    transition-duration: .05s;
  }
}

.callToActionText {
  padding: 20px 30px;
  color: white;
}
