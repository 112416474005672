

$cardColour: #f4f4f4;
$buyMealsButtonColour: #02BAA3;
$purchasePreviewBannerBG: #02BAA3;
$receiptHeaderColour: #02BAA3;


//Beneficiary Profile
$beneficiaryProfileHeaderColour: #02BAA3;
$beneficiary-name: #ffffff;

//default base colour
$base: #000;
//for cta buttons
$cta: #74B8AE;
//for links
$link: #74B8AE;
//primary colour for various copy that needs emphasis
$primary: #1f455f;
// light colour
$light: #666;
// for heading
$slate: #4f7477;
$dark-slate-blue: #1f455f;
//states
$error: #bc6767;
$success: #155724;
$placeholder: #999;
$placeholder-error: #bc6767;
$carousel-dot-active: #1f455f;
// for studio card texts
$slate: #4f7477;
// for studio detail text
$warm-grey: #9b9b9b;
$dusty-teal: #54939a;
$turquoise-blue: #00c3cf;
$cherry-red: #e40621;
$blue-grey: #8ab2b3;
$mariner: #3b5998;


//ProgressBar
$healthStatus_low: #bc6767;
$healthStatus_average: #FCE205;
$healthStatus_healthy: #155724;


// BLACK
$grey000: #FFFFFF;
$grey010: #f5f5f7;
$grey100: #F7F7F7;
$grey200: #EAEAEA;
$grey300: #DEDEDE;
$grey400: #BEBEBE;
$grey500: #A9A9A9;
$grey600: #7D7D7D;
$grey700: #686868;
$grey800: #434343;
$grey900: #1F1F1F;
$grey1000: #000000;
$darkTransparentScreen: rgba(0, 0, 0, 0.4);

//$secondaryAccent: #F2C200;
$secondaryAccent: #F1C40F;
$secondaryAccent300: #FDE897;


$cta: #74B8AE;
$link: #74B8AE;
$primaryAccent: #74B8AE;
$primaryAccentDark: #0a4d43;

$lineColour: #e6e6e6;

$errorColour: #ab2939;
