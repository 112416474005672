
.cardSupplementaryInfoBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.expirationBlock {
  flex: 0.65
}

.cvvBlock {
  flex: 0.32
}
