.profile-image-section{
  margin-top: 60px;
  //background: #02BAA3;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profile-image-block {
  display: flex;
  justify-content: center;

  .profile-picture-icon {
    //width: 50vw;
    //height: 50vw;
    max-width: 100px;
    max-height: 100px;
    border-radius: 50px;
  }
}

.profile-pic-explain {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  max-width: 400px;
  color: #4c4c4c;
  text-align: center;
  font-size: smaller;

}


.crop-container {
  position: absolute;
  //background: #f1c40f;
  background: #9b9b9b;
  //width: 80vw;
  //height: 100vh;
  //width: 100%; /* Full width (cover the whole page) */
  //height: calc(100% - 60px);
  //height: 70%;
  top: 0;
  left: 0;
  right: 0;
  bottom:0;
  //bottom: 350px;
  z-index: 10;

  .crop-block {
    background: #6e6e6e;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //bottom: 200px;
    //top: 0;
    //left: 0;
    //right: 0;

  };

  .crop-controls{
    //background: #231f20;
    //background: #4cd964;
    position: absolute;
    bottom: 0;
    margin-top: 40px;
    //left: 50%;
    width: 100%;
    //transform: translateX(-50%);
    //height: 10vh;
    //min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    .save-button{
      background: #f4f4f4;
      width: 200px;
      height: 70px;
      color: #000000;
      border-radius: 35px;
      margin: 50px 0px 20px 0px;
      //font-size: small;
    }
  }
}


