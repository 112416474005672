.stall-list-container {
  background: #F4F4F4;
  padding: 40px;
  border-radius: 20px;
  width: 80vw;
  max-width: 700px;
  margin-bottom: 40px;
}

.table-row {

  margin: 20px 10px;

  .stall-name{
    font-weight: bold;
    font-size: 1.8rem;
  }

  .stall-address{
    color: #4C4C4C;
    font-size: 1.5rem;
  }

}


.stall-name-block {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vegetarian-label {
  width: 70px;
  background: #f1c40f;
  text-align: center;
  padding: 3px 10px;
  color: #f4f4f4;
  border-radius: 10px;
  font-size: smaller;
  margin: 0px 5px;
  font-weight: bold;
}

.halal-label {
  width: 40px;
  background: #009000;
  text-align: center;
  padding: 2px 10px;
  color: #f4f4f4;
  border-radius: 10px;
  font-size: smaller;
    margin: 0px 5px;
  font-weight: bold;
}
