.landing-container {
  background: #F4F4F4;
//background: #e3f0f9;

  .stall-cards-collection {
    display: flex;
    justify-content: center;
    align-items: center;
    //background: #91c0ff;

    .stall-card-container {
      //background: #155724;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
    }
  }

  .loading {
    padding-top: 100px;
    height: 40vh
  }

  .landingPageAlign{
    display: flex;
    justify-content: center;
  }

  .landingPageHorizontalSeparator {
    margin: 60px 0px 30px 0px;
    height: 1px;
    width: 50%;
    background: #8ab2b3;
  }

  .supportedBy {
    background: white;
    height: 260px;
    width: 100vw;
  }

  .featuredEntriesSection {
    margin-top: 40px;
  }

  .supportedByText{
    background: white;
    padding: 30px 0px 0px 40px;
    font-size: 3rem;
    font-weight: bold;
  }

  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -15px; /* gutter size offset */
    max-width: 1280px;
    width: auto;
    padding: 30px;
    flex: 1;
  }

  .my-masonry-grid_column {
    //display: flex;
    padding-left: 15px; /* gutter size */
    background-clip: padding-box;
    //background: #bc6767;
    align-items: center;
    width: auto;
  }

  // Style your items
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    //background: grey;
    //margin-bottom: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

