@import "src/01_application/styles/colours";

.tableContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  //pading: 50px 10px;

  .processingPayment{
    color: #666666;
  }

  .tableRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 10px 10px 50px #e7e7e7;
    padding: 20px 20px;
    border-radius: 10px;
    margin: 5px 0px;

    .mainInfoContainer {
      //background: #5856d6;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 5px;
    }

    .leftInnerRowContainer {
      //background: #f1c40f;
      display: flex;
      flex-direction: column;

      .rowDate {
        color: #666666;
      }

      .displayName {
        font-weight: bold;
        font-size: 1.3em;
      }
    }

    .autoRepeatPurchase {
      margin-top: 5px;
      text-align: center;
      background: #3b5998;
      padding: 2px 20px;
      border-radius: 10px;
      color: #FFFFFF;
    }

    .monthlySubscription {
      margin-top: 5px;
      text-align: center;
      background: #155724;
      padding: 2px 20px;
      border-radius: 10px;
      color: #FFFFFF;
    }

    .userPurchaseStats {
      color: #4C4C4C;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }


    .rightInnerRowContainer {
      display: flex;
      flex-direction: row;
      //background: #155724;
      justify-content: space-between;

      .countContainer {
        //background-color: #155724;
        margin-top: 2px;
        font-size: 1.5em;
        font-weight: bold;
      }
    }


  }
}


.postMessageTextArea{
  margin-top: 20px;
  border: none;
  overflow: auto;
  color: #4c4c4c;
}

.viewMoreText{
  color: $cta;
  font-weight: bold;
  margin-top: 10px;
}
