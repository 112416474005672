@import "src/01_application/styles/colours";

.progressBarContainer {
  width: 90%;
  margin: 30px 0px 40px 0px;
}

.checkBoxBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.futureCircleStyle {
  width: 35px;
  height: 35px;
  background: #eaeaea;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inProgressCircle {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background: #FFFFFF;
  border: 3px solid $cta;
}

.completedCircle{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background: $cta;
  border-radius: 25px;
}

.checkIcon {
  width: 15px;
}

.unCrossedBridgeStyle {
  height: 7px;
  background: #eaeaea;
  width: 30%;
}

.acrossedBridgeStyle {
  height: 7px;
  background: $cta;
  width: 30%;
}

.textContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
  //background: #00c3cf;
  align-content: center;

}

.textBlock{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  max-width: 550px;
  width: 100%;
  //background: #1f455f;
}

.futureText{
  color: #9b9b9b;
}

.lightedText{
  color: $cta;
}

