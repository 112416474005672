@import "src/01_application/styles/colours.scss";
@import "src/01_application/styles/const.scss";



h1,
h2,
h3,
p,
i,
a,
li,
.first-letter,
.authorName a {
  text-rendering: optimizeLegibility;
}

.articlePageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}

.alignCenter {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.previewBanner {
  background: #f1c40f;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
  align-items: center;
  justify-content: space-between;
}

.contentContainer {
  margin-top: 20px;
  width: 95%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  //background: #bc6767;
}


.preivewBannerExitBtn {
  border: 2px solid #4c4c4c;
  padding: 5px 15px;
  border-radius: 20px;
  color: #4c4c4c;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }
}


.title {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size:58px;
  margin-top: 30px;
  width: 100%;

  @media (max-width: $mobileMenuBreakPoint) {
    font-size:38px;
  }
}

.authorAndDateContainer {
  //background: #00a699;
  margin: 10px 0px 25px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.authorProfilePicture {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.authorName {
  color: $cta;
  margin: 0px 20px 0px 10px;
}

.date {
  color: #82888a;
}

.hero {
  //background: #bc6767;
  width: 100%;
  //height: 400px;
  border-radius: 10px;
  object-fit: cover;
}

.singleImage {
  //background: #00a699;
  width: 80%;
  border-radius: 10px;
  object-fit: cover;
  margin-top: 15px;

  @media (max-width: $mobileMenuBreakPoint) {
    width: 90%;
  }
}

.imageCaption {
  font-size: 18px;
  font-family: "Lora";
  font-style: italic;
  color: #4c4c4c;
}

.h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 48px;
  margin-top: 40px;
  width: 60%;
  @media (max-width: $mobileMenuBreakPoint) {
    width: 90%;
  }
}


.h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 26px;
  width: 60%;
  font-weight: 700;
  margin-top: 40px;

  @media (max-width: $mobileMenuBreakPoint) {
    width: 90%;
  }
}


.h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  width: 60%;
  font-size: 24px;
  margin-top: 40px;

  @media (max-width: $mobileMenuBreakPoint) {
    width: 90%;
  }
}


.s1 {
  width: 60%;
  color: #82888a;
  font-size: x-large;

  @media (max-width: $mobileMenuBreakPoint) {
    width: 90%;
  }
}


.s2 {
  width: 60%;
  color: #82888a;
  font-size: large;

  @media (max-width: $mobileMenuBreakPoint) {
    width: 90%;
  }
}

.s3 {
  width: 60%;
  color: #82888a;

  @media (max-width: $mobileMenuBreakPoint) {
    width: 90%;
  }
}

.paragraph {
  width: 60%;
  line-height: 28px;
  font-size: 21px;
  font-family: "Lora";

  @media (max-width: $mobileMenuBreakPoint) {
    width: 90%;
    font-size: 18px;
  }
}
