@import "src/01_application/styles/colours";

.headerNavMain {
    //100% - btn width
    width: calc(100% - 20px);
    max-width: 900px;
    margin-top: 8px;
    text-align: center;
    transition: all .3s ease;
    //background: #74B8AE;
    //display: flex;
    //align-items: flex-end;

    @media (max-width: 992px) {
        display: block;
        width: 100%;
        text-align: left;
        margin-top: 30px;
    }

    &--condensed {
        max-width: 800px;
    }

    &__list {
        position: static;
        justify-content: space-evenly;
        display: flex;
        margin: 0;
        font-family: "Orator Std", Sans-Serif;

        //@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        //    justify-content: space-between;
        //    padding: 0 24px;
        //}

        @media (max-width: 992px) {
            display: block;
            overflow: auto;
        }
    }

    &--condensed .headerNavItem__link {
        @media (min-width: 993px) {
            font-size: 1.6rem
        }
    }
}

//.headerNavMain__sub {
//    position: absolute;
//    top: calc(100% + 6px);
//    display: none;
//    width: 190px;
//    padding: 12px 10px;
//    text-align: left;
//    list-style: none;
//    background: #eee;
//    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.12);
//    background: #666666;
//
//    @media (min-width: 993px) {
//        .HeaderNavItem:hover & {
//            display: block;
//            animation: fade-in 0.3s linear, from-top 0.2s ease-out;
//        }
//
//    }
//
//    @media (max-width: 992px) {
//        position: static;
//        display: none;
//        width: 100%;
//        padding: 0;
//        margin: -5px 0 10px;
//        background: #fff;
//        box-shadow: none;
//
//        .HeaderNavItem--active & {
//            display: block;
//        }
//
//    }
//
//    &-link {
//        display: block;
//        padding: 8px 10px;
//        color: #363737;
//        font-size: 1.4rem;
//        line-height: 1em;
//
//        @media (max-width: 992px) {
//            padding: 8px 10px 8px 15px;
//            font-size: 1.8rem;
//        }
//
//        &:hover {
//            color: $cta;
//        }
//
//        &--active,
//        &--active:focus {
//            color: $cta;
//        }
//
//    }
//
//}
