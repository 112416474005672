@import "src/01_application/styles/colours";



.status6Card {
  display: flex;
  flex:1;
  flex-direction: column;
  //background: #8ab2b3;
  //background: $cardColour;
  //height: 350px;
  border-radius: 30px;
  //margin: 30px 30px 30px 30px;
  max-width: 300px;
  min-width: 250px;
  //width:350px;
  align-items: center;
  text-align: center;
  box-shadow: 10px 10px 50px #e0e0e0;


  @media (max-width:360px) {
    //height:390px
  }


  &__info-section {
    //border-radius: 30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background: $cardColour;
    //background: #e40621;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    border-top: solid 3px #000000;
    border-left: solid 3px #000000;
    border-right: solid 3px #000000;

    .stall-name{
      color: #4C4C4C;
      font-weight: bold;
      font-size: 1em;
      margin-top: 40px;
    }

    .suspended-count{
      //background: #155724;
      //margin: 10px 100px 0px 100px;
      color: black ;
      font-size: 4em;
      margin-top: 10px;
      font-weight: bolder;

      &:hover{
        //color: $buyMealsButtonColour !important;
        // background: #155724;
      }
    }

    .view-details{
      margin-top: 10px;
      margin-bottom: 10px;
    }


    .stall-count-desc{
      color: black;
      font-size: 1em;
      padding: 0px 10px
    }

    .status6-description {
      color: #4C4C4C;
      padding: 10px;
      font-size:smaller;
      text-align: start;

    }


  }
}


.card{
    display: flex;
    flex:1;
    flex-direction: column;
    background: $cardColour;
    //height: 300px;
    border-radius: 30px;
    //margin: 30px 30px 30px 30px;
    max-width: 300px;
    min-width: 250px;
    //width:350px;
    align-items: center;
    text-align: center;
    box-shadow: 10px 10px 50px #e0e0e0;
  padding: 10px 10px;


    @media (max-width:360px) {
        //height:390px
    }

    &__info-section {
      border-radius: 30px;
        //border-top-left-radius: 30px;
        //border-top-right-radius: 30px;
        background: $cardColour;
        //background: #e40621;
        text-align: center;
        display: flex;
        flex-direction: column;
        width: 100%;
        //height: 450px;
        flex:1;

        .initiative-name{
            color: black;
            font-weight: bold;
            font-size: 1.2em;
            margin-top: 40px;
            margin-bottom: 40px;
        }


        .stall-name{
           color: #4C4C4C;
            font-weight: bold;
            font-size: 1em;
          margin-top: 40px;
        }

        .stall-address{
            font-size: 1em;
            color: #414141;
        }

        .suspended-count{
            //background: #155724;
            //margin: 10px 100px 0px 100px;
            color: black ;
            font-size: 4em;
            margin-top: 10px;
            font-weight: bolder;

            &:hover{
            //color: $buyMealsButtonColour !important;
               // background: #155724;
             }
        }

        .stall-count-desc{
            color: black;
            font-size: 1em;
          margin-bottom: 20px;
        }


      .progress-bar-block {
        margin-top: 30px;

        .stats{
          //background: #bc6767;
          margin: 0px 20px 0px 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .fontSize {
            font-size: smaller;
          }
        }
        .progress-bar {
          background: #e7e7e7;
          height: 5px;
          margin: 15px 20px 10px 20px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        .low{
          color: $healthStatus_low;
          font-weight: bold;
        }

        .average{
          color: $healthStatus_average;
          font-weight: bold;
        }

        .healthy{
          color: $healthStatus_healthy;
          font-weight: bold;
        }
      }



        .view-details{
          margin-top: 20px;
          margin-bottom: 30px;
        }

        .info-section{
            //background: #bc6767;
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 10px;
            font-size: 0.8em;
            color: #666666;
            justify-content: center;
            align-items: center;


            .info-icon {
                //background: #155724;
                width: 10%;
                height: 10%;
                color: #666666 ;
                filter: invert(50%) sepia(98%) saturate(12%) hue-rotate(314deg) brightness(100%) contrast(91%);
            }

            .info-text{
                padding-left: 10px;
            }
        }
    }


    .disable-button{
        background: #C5C5C5;
    }



    &__bottom-button{
        //position: absolute;
        background: $buyMealsButtonColour;
        height: 70px;
        //margin-top: 250px - 60;  //height of card less less height of button
        width:100%;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;


        &__buy-text {
            //margin-top: 20px;
            //margin-left: calc(90%/2);
            //margin-left: calc((100%/2) - 100px);
            margin-top:20px;
            //width: 200px;
            text-align: center;
            //background-color: #e40621;
            color: #F4F4F4;
            font-size: 1.5em;
            display: inline-block;
        }
    }


  &__status6-bottom-button{
    //position: absolute;
    background: $buyMealsButtonColour;
    height: 70px;
    //margin-top: 250px - 60;  //height of card less less height of button
    width:100%;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom: solid 3px #000000;
    border-left: solid 3px #000000;
    border-right: solid 3px #000000;


    &__buy-text {
      //margin-top: 20px;
      //margin-left: calc(90%/2);
      //margin-left: calc((100%/2) - 100px);
      margin-top:20px;
      margin-bottom: 20px;
      //width: 200px;
      text-align: center;
      //background-color: #e40621;
      color: #F4F4F4;
      font-size: 1.5em;
      display: inline-block;
    }
  }

  .footer {
    height: 30px;
  }



}
