@import "src/01_application/styles/colours";

.card {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: $cardColour;
  //background: #f1c40f;
  //height: 300px;
  border-radius: 30px;
  //margin: 30px 30px 30px 30px;
  max-width: 350px;
  //max-width: 500px;
  //width: 80vw;
  min-width: 250px;
  //width:350px;
  align-items: center;
  text-align: center;
  box-shadow: 10px 10px 50px #e0e0e0;

  @media (max-width: 360px) {
    //height:390px
    width: 80vw;
  }

  .disableButton {
    background: #C5C5C5;
  }

  .bottomButton {
    //position: absolute;
    background: $buyMealsButtonColour;
    height: 70px;
    //margin-top: 250px - 60;  //height of card less less height of button
    width: 100%;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;

    buyText {
      //margin-top: 20px;
      //margin-left: calc(90%/2);
      //margin-left: calc((100%/2) - 100px);
      margin-top: 20px;
      //width: 200px;
      text-align: center;
      //background-color: #e40621;
      color: #F4F4F4;
      font-size: 1.5em;
      display: inline-block;
    }
  }


}

.cardInfoSection {
  border-radius: 30px;
  //  border-top-left-radius: 30px;
  //  border-top-right-radius: 30px;
  //border-top-left-radius: 30px;
  //border-top-right-radius: 30px;
  background: $cardColour;
  //background: #e40621;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 450px;
  flex: 1;

  .landingInitiativeName {
    color: black;
    font-weight: bold;
    font-size: 1.2em;
    margin-top: 40px;
    margin-bottom: 5px;
    text-align: start;
    padding: 0px 20px;
  }

  .description {
    color: #414141;
    font-size: smaller;
    padding: 0px 20px;
    margin-bottom: 30px;
    text-align: start;
  }

  .suspendedCount {
    //background: #155724;
    //margin: 10px 100px 0px 100px;
    color: black;
    font-size: 4em;
    margin-top: 20px;
    font-weight: bolder;

    &:hover {
      //color: $buyMealsButtonColour !important;
      // background: #155724;
    }
  }

  .pending {
    color: black;
    font-size: 1.6rem;
  }


  .lowColour {
    color: $healthStatus_low;
    //font-weight: bold;
  }

  .averageColour {
    color: $healthStatus_average;
    //font-weight: bold;
  }

  .healthyColour {
    color: $healthStatus_healthy;
    //font-weight: bold;
  }

  .imageContainer {
    //width: 20%;
    height: 300px;
    //background: #f1c40f;
  }

  .image{
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 5px;
  }


  .progressBarBlock {
    margin-top: 30px;
    margin-bottom: 30px;;
    //background: #1f455f;

    .stats {
      //background: #bc6767;
      margin: 0px 20px 0px 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .fontSize {
        font-size: smaller;
      }
    }

    .progressBar {
      background: #e7e7e7;
      height: 5px;
      margin: 15px 20px 10px 20px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .low {
      color: $healthStatus_low;
      font-weight: bold;
    }

    .average {
      color: $healthStatus_average;
      font-weight: bold;
    }

    .healthy {
      color: $healthStatus_healthy;
      font-weight: bold;
    }
  }


  .viewClaimDetails {
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: smaller;
  }

  .viewDonationDetails {

    margin-top: 20px;
    font-size: smaller;
  }

  .infoSection {
    //background: #bc6767;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 0.8em;
    color: #666666;
    justify-content: center;
    align-items: center;


    .infoIcon {
      //background: #155724;
      width: 10%;
      height: 10%;
      color: #666666;
      filter: invert(50%) sepia(98%) saturate(12%) hue-rotate(314deg) brightness(100%) contrast(91%);
    }

    .infoText {
      padding-left: 10px;
    }
  }
}


.generalFundCta {
  display: flex;
  align-items: center;
  justify-content: center;


  .generalFundCtaText {
    color: #ffffff;
    //margin-top: 30px;
    //margin-bottom: 30px;
    width: 200px;
    height: 60px;
    background: $cta;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
}

