@import "src/01_application/styles/colours";


.page{
  display: flex;
  align-items: center;
  justify-content: center;
}

.successContainer {
  //background-color: #e40621;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
  max-width: 400px;

  .successMsg {
    font-size: 8rem;
    font-weight: bold;

    @media (max-width:500px) {
      font-size: 3em;
      //padding: 30px;
    }
  }

  .successSubMsg {
    margin-top: 20px;
    font-size: 1.2em;
    color: #666666;
    text-align: center;

    @media (max-width:500px) {
      font-size: 1.2em;
      //padding: 30px;
    }
  }

  .navToPurchaseClaimsButton {
    background: $purchasePreviewBannerBG;
    border-radius: 50px;
    margin-top: 30px;

  }

  .navToPurchaseClaimsText{
    color: white;
    font-size: 1.6rem;
    padding: 15px 35px 15px 35px;
    text-align: center;

    @media (max-width:500px) {
      font-size: 1.3em;
      //padding: 30px;
    }
  }

}
