@import "src/01_application/styles/colours";

.pageContainer{
  margin-top: 60px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  //background: #f4f4f4;
}



.cameraContainer {
  margin-top: 30px;
  width: 100%;
  display: grid;
  place-items: center;
  //background: #00c3cf;
}
.camera{

}



.home-btn {
  background: $buyMealsButtonColour;
  color: #ffffff;
  height: 60px;
  width: 250px;
  border-radius: 30px;
  margin-top: 30px;
  border-width: 0;
}


.clearFieldsBlock{
  display: flex;
  //align-items: center;
  justify-content: center;
}

.clearFieldsBtn{
  display: flex;
  flex: 1;
justify-content: flex-end;
  max-width: 700px;
  margin: 10px 20px;
  //background: #4c4c4c;
}

.aboutUsBlock {
  padding: 0px 20px;
  margin: 40px 0px 0px 0px;
  display: block;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  //background: #00c3cf;

  .aboutUsText {
    text-align: center;
    font-size: 5rem;
    font-weight: bold;
  }
}

.closed-block{
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;

  .closed-icon{
    width: 100px;

  }
}

.form-section {
  margin-top: 50px;
  width: 100vw;
  //background: #5856d6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


