@import "src/01_application/styles/colours";

.HeroSection {
  margin-top: 30px;
  min-height: 30vh;
  background: $cardColour;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //padding: 15px 100px 20px 100px;
  //padding-left: 20px;
  //padding-right: 20px;
  box-shadow: 10px 10px 50px #e0e0e0;
  //border: solid 3px #000000;
  //border-left: solid 3px #000000;
  //border-right: solid 3px #000000;

  //@media (min-width: 1200px) {
  //  min-height: 90vh;
  //  margin-top: 30px;
  //}
  //
  //@media (max-width: 400px) {
  //  margin-top: 90px;
  //  min-height: 140vh;
  //  padding: 5px 30px 20px 30px;
  //}
  //
  //@media (max-width: 570px) {
  //  margin-top: 90px;
  //  min-height: 90vh;
  //  padding: 5px 30px 20px 30px;
  //}
}


.statsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 90vw;
  max-width: 1000px;
  //border: solid #000;
  //border-radius: 20px;
  //border-width: 1px;
  //background: #155724;

  .stallDetails {
    //background: #bc6767;
    display: flex;
    flex-direction: column;
    margin: 30px 30px 0 30px;

    .stallName {
      font-weight: bold;
    }

    .stallAddress {
      color: #4C4C4C;
    }
  }

  .graph {
    //background: #1f455f;
    margin: 30px 0px 40px 0px;
    display: grid;
    align-items: center;
  }
}


.emptyStateBlock {
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .plateIcon {
    width: 80px;
    height: 80px;
    margin-bottom: 30px;
  }
}

.boldStallName {
  font-weight: bold;
}
