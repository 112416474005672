@import "src/01_application/styles/colours";



.labelText {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.subLabelText {
  margin-top: -4px;
  margin-bottom: 10px;
  color: #4C4C4C;
  font-size: smaller;
}

.textInput {
  padding: 12px 10px;
  font-size: 16px;
  //background: #bc6767;
  width: 90%;
  //height: 56px;
  border: 2px solid #e0e0e0;
  border-radius: 5px;
  position: relative;
  transition: 0.3s all;
  margin-bottom: 25px;

  &:focus {
    border: 2px solid #000000;
    outline: none;

  }
}

.error {
  border: 5px solid #e40621;
}


.supplementaryCCInfo {
  display: flex;
  flex-direction: row;
  //background: #bc6767;
  //align-items: ;

}

//@media (max-width: 992px) {
//  flex-direction: column;
//}


.dateOfExpiryBlock {
  display: flex;
  flex-direction: column;
  //margin-right: 150px;
  //background: #155724;
  width: 180px;
}

.expDetailsInput {
  display: flex;
  flex-direction: row;
  //background-color: #e40621;
  //flex-direction: row;
  //justify-content: space-between;
  //padding-right: 30px;
}

.month {
  width: 30px;
  //min-width: 80px;
}

.year {
  width: 50px;
  //min-width: 100px;
  margin-left: 30px;
}

.CCV {
  flex-direction: column;
  //width: 40px;
  //background: #00c3cf;
}

.ccv {
  width: 40px
}

.buttonSection {
  //background: #e40621;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 460px) {
    flex-direction: column-reverse;
  }
}

.purchaseMealsCTA {
  background: $cta;
  //margin-top: 40px;
  height: 45px;
  width: 300px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .purchaseMealsCTAText {
    color: white;
    font-weight: bold;
    //font-size: 1.2em;
  }

  &:hover {
    cursor: pointer;
  }
}


.backBtnSection {
  //background: #155724;
  margin-bottom: 10px;
  margin-top: 10px;

  .backBtn {
    display: flex;
    margin: 5px;
    border: 2px solid #e0e0e0;
    height: 45px;
    width: 90px;
    border-radius: 35px;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size: 1em;
    color: #9b9b9b;

    @media (max-width: 460px) {
      height: 40px;
      width: 290px;
    }


    &:hover {
      border: 4px solid black;
      height: 36px;
      width: 86px;
      color: black;
      transition: 0.35s
    }
  }
}
