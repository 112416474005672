@import "src/01_application/styles/colours";
@import "src/01_application/styles/const";

.buttonBlock {
  display: flex;
  flex-direction: row;
  margin: 40px 0px 20px 0px;
  justify-content: space-between;
  align-items: center;

  @media (max-width:$mobileMenuBreakPoint) {
    width: 100%;
    flex-direction: column;
  }
}

.secondaryButton {
  background: #ececec;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  //font-weight: bold;
  height: 50px;
  width: 40%;
  border-radius: 5px;
  //margin-top: 30px;


  &:hover {
    font-weight: bold;
    transition: all .3s ease-out;
    color: #000000;
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }

  @media (max-width:$mobileMenuBreakPoint) {
    width: 100%;
  }
}

.primaryButton {
  background: $grey800;
  border: none;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 50px;
  width: 40%;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    color: #ffffff;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }
  @media (max-width:$mobileMenuBreakPoint) {
    margin-top: 20px;
    width: 100%;
  }
}

.primaryButtonHardEdge {
  background: $grey800;
  border: none;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 50px;
  width: 40%;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    color: #ffffff;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }
  @media (max-width:$mobileMenuBreakPoint) {
    width: 90%;
  }
}

.secondaryButtonHardEdge {
  background: #ececec;
  border: none;
  color: #494949;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 50px;
  width: 40%;
  border-radius: 0px;

  &:hover {
    cursor: pointer;
    color: #ffffff;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }
  @media (max-width:$mobileMenuBreakPoint) {
    width: 90%;
  }
}

.outlineButtonHardEdge {
  background: none;
  border: 1.5px solid $cta;
  color:  $cta;
  display: flex;
  justify-content: center;
  align-items: center;
  //font-weight: bold;
  height: 50px;
  width: 40%;
  border-radius: 0px;

  &:hover {
    cursor: pointer;
    //color: #ffffff;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }
  @media (max-width:$mobileMenuBreakPoint) {
    width: 90%;
  }
}