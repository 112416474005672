@import "src/01_application/styles/colours";

.pageContainer{
display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.registrationSuccessBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10%;
  max-width: 700px;
  padding: 0px 15px;


}

.completeLogo {
  width: 100px;
  margin-bottom: 40px;
}

.beneficiaryURL {
  width: 90vw;
  //font-weight: bold;
  background: #CCCCCC;
  padding: 10px 5px;
  margin-bottom: 20px;
}

.homeBtn {
  background: $buyMealsButtonColour;
  color: #ffffff;
  height: 60px;
  width: 250px;
  border-radius: 30px;
  margin-top: 30px;
  border-width: 0;
}

