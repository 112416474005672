.formBlock {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 600px;
  z-index: 0;
}

.heading2Text {
  font-size: 3rem;
  margin: 20px 0px 10px 0px;
  //font-weight: bold;
}

.formField {
  padding: 12px 10px;
  //font-size: large;
  //background: #bc6767;
  //width: 90%;
  //height: 56px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  position: relative;
  transition: 0.3s all;
  margin-top: 15px;

  &:focus {
    border: 3px solid #000000;
    outline: none;

  }

  &__error {
    border: 3px solid #e40621;
  }
}

