.autoRepeatPurchase {
  text-align: center;
  //height: 20px;
  //background: #155724;
  background: #3b5998;
  //padding: 2px 20px;
  border-radius: 10px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0px;
}

.monthlySubscription {
  padding: 2px 0px;
  text-align: center;
  //height: 20px;
  //background: #155724;
  background: #74B8AE;
  //padding: 2px 20px;
  border-radius: 10px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;



}

.tagText{
  padding: 4px 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
}