@import "src/01_application/styles/const";
@import "src/01_application/styles/colours";

.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.profileContainer {
  margin-top: 100px;
  width: 100%;
  max-width: 1200px;
  //@media (min-width: 1200px) {
  //  width: 1200px;
  //}

}

.greetingText {
  font-weight: bold;
  font-size: 2em;
  margin-left: 20px;
}

.ordersContainer {
  display: flex;
  background: #f3f3f3;
  //width: 105vw;
  //margin-left: -2.5%;
  margin-top: 40px;
  justify-content: center;

  @media (min-width: 1200px) {
    //margin-left: calc(-50vw + 600px - 20px);
    //background: #37b9a2;
  }
}

.ordersBlock {
  //background: #37b9a2;
  width: 95%;
  max-width: 1200px;
}

.lineBetweenPaymentAndFulfilmentBlock {
  height: 1px;
  background: #dddddd;
  margin: 40px 0px;
}

.sectionHeaderText {
  font-weight: bold;
  font-size: 2em;
  margin: 30px 0px 0px 20px;
}

.detailsContainer {
  //background: #d5d5d5;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 820px) {
    flex-direction: column;
  }

  .headingBlock {
    flex: 0.20;
    //background: #f1c40f;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 30px 0px 0px 20px;


    .headingText {
      font-weight: bold;
      font-size: 1.7em;
    }
  }


  .contentDetailsBlock {
    flex: 0.79;
    @media (max-width: $mobileMenuBreakPoint) {
      margin-top: 10px;
    }
    //background: #f1c40f;
  }
}

.bottomSpacer {
  height: 100px
}


.modalContainer {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  //align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh + 60px);
  z-index: 50;
  margin-top: -$headerHeight;
  overflow-y: scroll;

  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

.modalCard {
  background: #FFFFFF;
  width: 90%;
  height: 300px;
  max-width: 600px;
  margin-top: 20%;
  border-radius: 10px;

  @media (max-height: 700px) {
    margin-top: 10%;
  }

}

.modalHeadingText {
  font-size: 2em;
  font-weight: bold;
  margin: 70px 20px 20px 20px;
}

.buttonBlock {
  display: flex;
  margin: 40px 20px 20px 20px;
  justify-content: space-between;
  align-items: center;
}

.secondaryButton {
  background: #ececec;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  //font-weight: bold;
  height: 50px;
  width: 40%;
  border-radius: 10px;
  //margin-top: 30px;


  &:hover {
    font-weight: bold;
    transition: all .3s ease-out;
    color: #000000;
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }
}

.primaryButton {
  background: $cta;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 50px;
  width: 40%;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    color: #ffffff;
  }

  &:active {
    transform: translateY(1px);
    transition-duration: .05s;
  }
}

.formContainer {
  margin-top: 30px;
  background: #FFFFFF;
  border-radius: 10px;
  width: 95%;
  //height: 95vh;
  max-width: 600px;
  display: flex;
  justify-content: center;

  max-height: calc(100vh - 60px);
  overflow-y: auto;
}

.spacer{
  height: 30px;
}

.formBlock{
  width: 95%;
  margin-top: 30px;
  //background: #f1c40f;
}

.buttonBlock{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
