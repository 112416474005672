@import "src/01_application/styles/colours";


.table-container {
  //background: $cardColour;
  //background: #F9F9F9;
  ////background: #3b5998;
  //padding: 50px;
  //margin: 0px 40px 0px 40px;
  //max-width: 800px;
  //border-radius: 40px;
  //box-shadow: 10px 10px 50px #e0e0e0;

  .table-row-container {
    padding: 10px 5px 10px 5px;
    margin: 5px 0px 20px 0px;
    //background: #3b5998;

    @media (max-width: 550px) {
      padding: 10px 5px 10px 5px;
    }

    //.date-time-section{
    //  display: flex;
    //  flex-direction: row;
    //  justify-content: space-between;
    //  //background: #155724;
    //  color: #414141;
    //}


    .date-time-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background: #74B8AE;
      //color: #414141;
      color: white;
      padding: 3px 15px;
      border-radius: 3px;
    }

    .beneficiary {
      //margin-top: 5px;
      margin-bottom: 10px;
      margin-left: 30px;
      font-size: 2.5rem;
      //font-weight: bolder;
    }

  }

}


.rowDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  //justify-content: center;
}

.stallDetails {
  margin-top: 10px;
  //background: #e2e2e2;
  margin-left: 30px;
  padding: 0px 0px;
  border-radius: 5px;

}


.stallIcon {
  width: 20px;
}

.userIcon {
  width: 20px;
}

.stallName {
  //margin-top: 10px;
  font-weight: bold;
  color: #414141;
}

.stallAddress {
  color: #414141;
  margin-bottom: 15px;
}

.spacer {
  //width: 80%;
  padding: 0px 40px;
  margin-top: 20px;
  height: 1px;
  background: #e2e2e2;
  //background: #74B8AE;
}

