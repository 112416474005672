@import "src/01_application/styles/colours";

.container{
  margin: 40px 40px 20px 40px;
  display: flex;
  flex-direction: column;
  //background: #1f455f;
}

.lineBlock{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.unverifiedTag {
  background: #b70707;
  color: #FFFFFF;
  width: 80px;
  text-align: center;
  padding: 4px 6px;
  border-radius: 5px;
}

.verifiedTag {
  background: $cta;;
  color: #FFFFFF;
  width: 60px;
  text-align: center;
  padding: 4px 6px;
  border-radius: 5px;
}